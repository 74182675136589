import React, { useState, useEffect } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getCategoryStart,
  getCategorySuccess,
  getCategoryFailure,
} from "./../redux/categorySlice";

import {
  fetchSubCategoryStart,
  fetchSubCategorySucces,
  fetchSubCategoryFailure,
  addProductStart,
  addProductSuccess,
  addProductFailure,
} from "../redux/productsSlice";

import Img1 from "../images/img1.jpg";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#2B7FA4",
    },
  },
  typography: {
    color: "#737B7D",
    textDecoration: "none",
    button: {
      textTransform: "none",
      fontSize: "1rem",
      backgroundColor: " #2B7FA4",
    },
  },
});

const styles = {
  paperContainer: {
    height: "200px",
    backgroundImage: `url(${Img1})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    width: "400px",
    textAlign: "center",
  },
  imageUpload: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: " -25px 0 0 -25px",
  },
};

export default function AjouterProduit() {
  const [value, setValue] = React.useState("Controlled");
  const [navRef, setnavRef] = useState("");
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [prix, setPrix] = useState("");
  const [description, setDescription] = useState("");
  const [message, setMessage] = useState(null);
  const [brand, setBrand] = useState("");

  const [subCategory, setSubCategory] = useState("");
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState("");
  const [isRevet, setIsRevet] = useState("");
  const [couverture, setCouverture] = useState({});

  const categories = [
    { name: "sanitaire", id: "614366ef8944882f54a6eeb7" },
    { name: "Chauffage et Climatisation", id: "61436802b170083b1c74a191" },
    { name: "Robinetterie", id: "61436a38966bbb3a2446456c" },
    { name: "Porte, cuisine et dressing", id: "61bbace13883851f204a5c90" },
    { name: "Revêtement", id: "634d784a4ef4b43937e9563e" },
    { name: "Quincaillerie générale", id: "634d78724ef4b43937e95641" },
  ];

  const listSub = useSelector((state) => state.products.subCategoryList) || [];
  console.log("liiiiiiist", listSub);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("befooor", subCategory);
    const getSubCategory = async (c) => {
      try {
        console.log("caaaaaaat", category);

        const res = await axios.get(
          `/api/subCategory/findByCategory?c=${category}`
        );
        console.log("suuuub", res.data);
        dispatch(fetchSubCategorySucces(res.data));
      } catch (error) {
        console.log(error);
      }
    };
    getSubCategory();
  }, [subCategory, category, dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(addProductStart());
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(
        "/api/products",
        {
          navRef,
          name,
          designation,
          description,
          category,
          subCategory,
          images,
          brand,
          isRevet,
        },
        config
      );
      console.log("resuuuult", res.status);
      dispatch(addProductSuccess(res.data));
      if (res.status === "201") {
        <Navigate to="/dashboard" />;
      }
    } catch (error) {
      dispatch(addProductFailure());
    }
  };
  const uploadFileHandler = async (e) => {
    const files = Array.from(couverture).concat(Array.from(e.target.files));
    console.log("fiiiiiiles ", files);
    const formData = new FormData();
    console.log();
    for (const file of files) {
      formData.append("files", file);
    }

    setLoading(true);
    try {
      const config = {
        Headers: {
          "Content-Type": "multipart/form/data",
        },
      };

      const { data } = await axios.post("/api/upload", formData, config);
      console.log("imaaaages", data);
      setImages(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Typography variant="h4" sx={{ color: "#737B7D" }} m={2}>
        Ajouter un Produit
      </Typography>
      <Grid>
        <Box
          component="form"
          sx={{
            ml: 2,
          }}
          spacing={2}
          noValidate
          autoComplete="on"
        >
          <Grid m={2}>
            <TextField
              required
              id="outlined-multiline-flexible"
              label="Code Article"
              placeholder="Code Article"
              color="secondary"
              maxRows={5}
              onChange={(e) => setnavRef(e.target.value)}
            />
            <TextField
              required
              id="outlined-multiline-flexible"
              label="Nom d'article"
              placeholder="Nom d'article"
              multiline
              color="secondary"
              maxRows={5}
              sx={{ ml: 2 }}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid m={2}>
            <TextField
              required
              id="outlined-multiline-flexible"
              label="Designation"
              placeholder="Designation"
              multiline
              color="secondary"
              maxRows={5}
              onChange={(e) => setDesignation(e.target.value)}
            />
            <TextField
              required
              id="outlined-multiline-flexible"
              label="Prix"
              placeholder="Prix"
              multiline
              sx={{ ml: 2 }}
              color="secondary"
              value=""
              maxRows={5}
              onChange={(e) => setPrix(e.target.value)}
            />

            {/* <TextField
              required
              id="outlined-multiline-flexible"
              label="Catégorie"
              placeholder="Selectionner la categorie"
              // value=""
              color="secondary"
              maxRows={5}
              onChange={(e) => setCategory(e.target.value)}
            /> */}
            <FormControl fullWidth>
              <InputLabel> Categorie </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={cat}
                label="Categorie"
                onChange={(e) => setCategory(e.target.value)}
              >
                {categories.map((category) => (
                  <MenuItem value={category.id}>{category.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel> Categorie </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={cat}
                label="Sous Categorie"
                onChange={(e) => setSubCategory(e.target.value)}
              >
                {listSub.map((subcategory) => (
                  <MenuItem value={subcategory._id}>
                    {subcategory.subname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              required
              id="outlined-multiline-flexible"
              label="Marque"
              placeholder="Selectionner la sous categorie"
              // value=""
              color="secondary"
              maxRows={5}
              onChange={(e) => setBrand(e.target.value)}
            />
          </Grid>
          <FormControl fullWidth>
            <InputLabel> Produit revetement ? </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="revêtement ?"
              onChange={(e) => setIsRevet(e.target.value)}
            >
              <MenuItem value={true}> true</MenuItem>
              <MenuItem value={false}> false</MenuItem>
            </Select>
          </FormControl>

          <Grid m={2}>
            <TextField
              required
              id="outlined-multiline-flexible"
              label="Description"
              placeholder="Description"
              multiline
              rows={10}
              defaultValue=""
              color="secondary"
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>
          {/* couverture */}
          <input
            //  hidden
            //  accept="image/*"
            multiple
            type="file"
            onChange={(e) => setCouverture(e.target.files)}
          />
          <Grid m={2} style={styles.paperContainer}>
            <input
              //  hidden
              //  accept="image/*"
              multiple
              type="file"
              // onChange={(e)=> setImages(e.target.files)}
              onChange={uploadFileHandler}
            />
            <Button
              variant="contained"
              component="label"
              color="secondary"
              style={styles.imageUpload}
              // onClick={uploadFileHandler}
            >
              Ajouter
            </Button>
            {loading && <CircularProgress />}
          </Grid>
        </Box>
        <Button
          m={2}
          variant="contained"
          component="button"
          color="secondary"
          onClick={handleSubmit}
        >
          Validation
        </Button>
      </Grid>
    </ThemeProvider>
  );
}
