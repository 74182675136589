import * as React from "react";
import { Container } from "@mui/system";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import {
  Box,
  Grid,
  Typography,
  Divider,
  ImageList,
  ImageListItem,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Navbar from "../Navbar/Navbar";
import Partner from "../Partner";
import Footer from "../Footer/Footer";
import Img1 from "../../images/299773591_5677824965595089_7339720409240945486_n.jpg";
import cover from "../../images/cover.jpg";
import Img2 from "../../images/img1.jpg";
import Img3 from "../../images/img4.jpg";
import Img4 from "../../images/img3.jpg";
import Log from "../../images/logo.png";
import Ins1 from "../../images/inside1.JPG";
import Ins2 from "../../images/inside2.JPG";
import Ins3 from "../../images/inside2.JPG";

import "./About.css";

const Img = styled("img")({
  maxWidth: "100%",
  maxHeight: "100%",
});

const Logo = styled("img")({
  width: "20%",
  maxHeight: "10%",
});

const theme = createTheme({
  typography: {
    width: "610px",
    height: "190px",
    left: "730px",
    top: "357px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "150%",
  },
  li: {
    width: "610px",
    height: "190px",
    left: "730px",
    top: "357px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "150%",
  },
});

const About = () => {
  return (
    <ThemeProvider theme={theme}>
      <Navbar />
      <Container maxWidth="xl">
        <Grid>
          {/* <Breadcrumbs
            aria-label="breadcrumb"
            sx={{
              mb: 1,
            }}
          >
            <Link underline="hover" color="inherit" href="/about">
              A propos
            </Link>
          </Breadcrumbs> */}
          <Typography
            variant="h6"
            component="div"
            sx={{
              m: 2,
              ml: 0,
              fontWeight: "bold",
              color: "#2B7FA4",
            }}
          >
            A propos
          </Typography>
        </Grid>
      </Container>
      <Container maxWidth="xl">
        <Grid container sx={{ mb: 5, alignItems: "center", mt: 7 }}>
          <Grid item xs={12} sm={5} md={5}>
            <Img alt="" src={cover} />
          </Grid>

          <Grid item xs={12} sm={7} md={7} sx={{ textAlign: "justify" }}>
            <Box sx={{ display: "flex" }} pl={4}>
              <Logo src={Log} className="logotitle" />
              <Typography
                sx={{
                  m: "auto 0",
                  textAlign: "left",
                  fontWeight: "bold",
                  fontSize: "18px",
                  color: "#2B7FA4",
                }}
                className="name"
              >
                La société Lumière Mahboubine SLM
              </Typography>
            </Box>
            <Typography p={2} variant="body1" gutterBottom className="about">
              Depuis 1976, la société Lumière Mahboubine (SLM) propose une vaste
              gamme de produits de qualité pour les particuliers et les
              professionnels, notamment des revêtements, des articles
              sanitaires, des robinetteries, des systèmes de chauffage et de
              climatisation, de la quincaillerie générale, des portes, des
              cuisines et des dressings. En travaillant avec des partenaires
              locaux et internationaux réputés tels que SOPAL, DURAVIT, AZTECA,
              INALCO, FRÄNKISCHE et OLI..., SLM offre une sélection diversifiée
              de produits de qualité à sa clientèle. Les conseillers commerciaux
              de l'entreprise sont disponibles pour aider les clients à prendre
              des décisions éclairées lors de leurs achats. Actuellement, SLM se
              concentre sur la région côtière de la Tunisie et possède 5
              showrooms situés dans le Grand Tunis et à Kairouan. Bien que cela
              limite son marché potentiel, l'entreprise peut poursuivre son
              expansion dans d'autres régions de la Tunisie.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container
        maxWidth="xxl"
        sx={{
          bgcolor: "#FCF8F3",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sm={6}
            md={7}
            sx={{ margin: " 2%", textAlign: "justify" }}
            className="solution"
          >
            <Typography p={4}>
              Nos solutions : <br />
              Si vous êtes un particulier, un professionnel ou un artisan en
              quête de produits de qualité pour réaliser vos projets, nous avons
              la solution pour vous. Avec une sélection de plus de 40 000
              articles répartis sur plusieurs gammes, vous pouvez être sûr de
              trouver ce dont vous avez besoin.
            </Typography>
            <Divider
              style={{
                border: "2px solid #2B7FA4",
                backgroundColor: "#2B7FA4",
                margin: "0px 0px 0px 30px",
                width: "50%",
              }}
            />
            <Grid container spacing={2} sx={{ margin: " 2%" }}>
              <Grid
                className="fbb"
                item
                xs={12}
                sm={6}
                md={3}
                sx={{ display: "flex", alignItems: "center", mb: 2, mt: 2 }}
              >
                <FacebookOutlinedIcon sx={{ mr: 1, color: "#2B7FA4" }} />
                <Link
                  href="https://www.facebook.com/SLMELKATEB"
                  color="inherit"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <Typography>SLMELKATEB</Typography>
                </Link>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                sx={{ display: "flex", alignItems: "center", mb: 2, mt: 2 }}
              >
                <InstagramIcon sx={{ mr: 1, color: "#2B7FA4" }} />
                <Link
                  href="https://www.instagram.com/societe_lumiere_mahboubine/"
                  color="inherit"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <Typography>SLM_ELKATEB</Typography>
                </Link>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                sx={{ display: "flex", alignItems: "center", mb: 2, mt: 2 }}
              >
                <EmailRoundedIcon sx={{ mr: 1, color: "#2B7FA4" }} />
                <Typography>slm@slm.com.tn</Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{ display: "flex", alignItems: "center", mt: 2 }}
              >
                <LocalPhoneIcon sx={{ mr: 1, color: "#2B7FA4" }} />
                <Typography>+216 31336060</Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                sx={{ display: "flex", alignItems: "center", mt: 2 }}
              >
                <LocationOnIcon sx={{ mr: 1, color: "#2B7FA4" }} />
                <Typography>
                  Siège: 115, Avenue 2 Mars 1934,Cite El Habib Ezzahra
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <ImageList sx={{ m: 4 }} cols={2} rowHeight={150} variant="masonry">
              <ImageListItem sx={{ mx: 1 }}>
                <img alt="" src={Ins1} />
              </ImageListItem>
              <ImageListItem sx={{ mx: 1, my: 4 }}>
                <img alt="" src={Ins2} />
              </ImageListItem>
              <ImageListItem sx={{ mx: 1, my: 4 }}>
                <img alt="" src={Ins3} />
              </ImageListItem>
              <ImageListItem sx={{ mx: 1, my: 4 }}>
                <img alt="" src={Img1} />
              </ImageListItem>
            </ImageList>
          </Grid>
        </Grid>
      </Container>
      <Partner />
      <Footer />
    </ThemeProvider>
  );
};
export default About;
