import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  getCategoryStart,
  getCategorySuccess,
  getCategoryFailure,
  getSubCategoryStart,
  getSubCategorySuccess,
  getSubCategoryFailure,
} from "../redux/categorySlice";

export default function Filter() {
  const [isOpenCollapse, setIsOpenCollapse] = useState(null);
  const { categories } = useSelector((state) => state.category);
  const { subcategories } = useSelector((state) => state.category);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (index) => {
    // setOpen(!open);
    if (isOpenCollapse === index) {
      setIsOpenCollapse(null);
    } else {
      setIsOpenCollapse(index);
    }
  };

  const handleGetName = (id) => {
   
    if (categories && categories.length > 0) {
      const res = categories.filter((cat) => cat._id === id);
   
      if (res && res.length > 0) {
        return res[0].name;
      }
    }
    return "";
  };
  useEffect(() => {
    const getAllcategory = async () => {
      dispatch(getCategoryStart);
      try {
        // category use selector not use state don't forget
        const res = await axios.get("https://dev.server.slm.com.tn/api/category");
        dispatch(getCategorySuccess(res.data));
        
      } catch (error) {
        dispatch(getCategoryFailure());
      }
    };
    getAllcategory();
  }, []);

  useEffect(() => {
    const getAllSubCategory = async () => {
      dispatch(getSubCategoryStart);
      try {
        // category use selector not use state don't forget
        const res = await axios.get(
          "https://dev.server.slm.com.tn/api/subCategory/grouped"
        );
        dispatch(getSubCategorySuccess(res.data));
        
      } catch (error) {
        dispatch(getSubCategoryFailure());
      }
    };
    getAllSubCategory();
  }, []);

  const width = window.screen.width;

  return (
    <Grid sx={{ width: "100%" }}>
      <details open={width > 600}>
        <summary
          style={{
            fontSize: 20,
            fontWeight: "Bold",
            letterSpacing: 0,
            color: "#0C0C0C",
            padding: "15px 5px",
            userSelect: "none",
            cursor: "pointer",
          }}
        >
          Tous les produits
        </summary>

        {subcategories.map((group, index) => (
          <Grid sx={{ display: "table", width: "100%" }}>
            <ListItemButton onClick={() => handleClick(index)}>
              <ListItemText
                primaryTypographyProps={{
                  color: "#434447",
                  fontFamily: "Gilroy",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "16px",
                }}
                primary={handleGetName(group._id)}
              />
              {isOpenCollapse === index ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse
              in={isOpenCollapse === index}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {group.all.map((single) => {
                  const labelId = `checkbox-list-label-${single._id}`;
                  return (
                    <ListItem key={single._id} disablePadding>
                      <ListItemButton
                        primaryTypographyProps={{
                          color: "#434447",
                          fontFamily: "Gilroy",
                          fontStyle: "normal",
                          fontSize: "16px",
                        }}
                        role={undefined}
                        onClick={() => navigate(`/products/${single._id}`)}
                        dense
                      >
                        <ListItemText
                          id={labelId}
                          primary={single.subname}
                          sx={{ ml: 2 }}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Collapse>
          </Grid>
        ))}
      </details>
    </Grid>
  );
}
