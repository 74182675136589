import * as React from "react";
import { Box, Grid } from "@mui/material";

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import Img1 from "../images/img1.jpg";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#2B7FA4",
    },
  },
  typography: {
    color: "#737B7D",
    textDecoration: "none",
    button: {
      textTransform: "none",
      fontSize: "1rem",
      backgroundColor: " #2B7FA4",
    },
  },
});
const styles = {
  paperContainer: {
    height: "200px",
    backgroundImage: `url(${Img1})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    width: "400px",
    textAlign: "center",
  },
  imageUpload: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: " -25px 0 0 -25px",
  },
};

export default function ModifierProduit() {
  const [value, setValue] = React.useState("Controlled");

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <ThemeProvider theme={theme}>
      <Typography variant="h4" sx={{ color: "#737B7D" }} m={2}>
        Modifier un Produit
      </Typography>
      <Grid>
        <Box
          component="form"
          sx={{
            ml: 2,
          }}
          spacing={2}
          noValidate
          autoComplete="on"
        >
          <Grid m={2}>
            <TextField
              required
              id="outlined-multiline-flexible"
              label="Code Article"
              placeholder="Code Article"
              value=""
              onChange={handleChange}
              color="secondary"
            />
            <TextField
              sx={{ ml: 2 }}
              required
              id="outlined-multiline-flexible"
              label="Code Article"
              placeholder="Code Article"
              value=""
              onChange={handleChange}
              color="secondary"
            />
          </Grid>
          <Grid m={2}>
            <TextField
              id="outlined-multiline-flexible"
              label="Designation"
              placeholder="Designation"
              multiline
              maxRows={4}
              value=""
              onChange={handleChange}
              color="secondary"
            />
            <TextField
              id="outlined-multiline-flexible"
              label="Prix"
              placeholder="Prix"
              multiline
              maxRows={4}
              value=""
              onChange={handleChange}
              sx={{ ml: 2 }}
              color="secondary"
            />
          </Grid>
          <Grid m={2}>
            <TextField
              id="outlined-multiline-flexible"
              label="Description"
              placeholder="Description"
              multiline
              rows={10}
              defaultValue=""
              color="secondary"
            />
          </Grid>
          <Grid m={2} style={styles.paperContainer}>
            <Button
              variant="contained"
              component="label"
              color="secondary"
              style={styles.imageUpload}
            >
              Ajouter
              <input hidden accept="image/*" multiple type="file" />
            </Button>
          </Grid>
        </Box>
        <Button m={2} variant="contained" component="button" color="secondary">
          Validation de la produit
        </Button>
      </Grid>
    </ThemeProvider>
  );
}
