import * as React from "react";
import { Grid } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

export default function ProductItem({ product }) {
  const serv = "https://dev.server.slm.com.tn";
  return "products" in product ? (
    <Link
      to={`/product/revetement/${product._id}`}
      style={{ textDecoration: "none" }}
    >
      <Card
        sx={{
          backgroundColor: "#F4F5F7",
          position: "relative",
          flexDirection: "column",
          height: "100%",
          display: "flex",
          ":hover": {
            transform: "scale(1.0)",
            boxShadow: "1px 4px 14px #000",
          },
        }}
      >
        <Grid sx={{ height: "20em" }}>
          <CardMedia
            component="img"
            height="100%"
             image={serv.concat(product.images[0])}
            //image={product.images[0]}
            alt="Product Image"
          />
        </Grid>

        <CardContent sx={{ flexGrow: 1 }}>
          <Typography
            gutterBottom
            sx={{ textAlign: "left" }}
            variant="h5"
            component="h2"
          >
            {product.name}
          </Typography>
          <Typography sx={{ textAlign: "left", color: "#898989" }} gutterBottom>
            Revêtement
          </Typography>
          <Button
            variant="contained"
            sx={{
              fontFamily: "Gilroy",
              background: "#2B7FA4",

              color: "white",
            }}
          >
            Découvrez
          </Button>
        </CardContent>
      </Card>
    </Link>
  ) : (
    <Link to={`/product/${product._id}`} style={{ textDecoration: "none" }}>
      <Card
        sx={{
          backgroundColor: "#F4F5F7",
          position: "relative",
          flexDirection: "column",
          height: "100%",
          display: "flex",
          ":hover": {
            transform: "scale(1.0)",
            boxShadow: "1px 4px 14px #000",
          },
        }}
      >
        <Grid sx={{ height: "20em", backgroundColor: "white" }}>
          <CardMedia
            component="img"
            height="100%"
            sx={{ objectFit: "scale-down" }}
            image={serv.concat(product.images[0])}
            //image={product.images[0]}
            alt="Product Image"
          />
        </Grid>

        <Typography
          gutterBottom
          sx={{ textAlign: "left", fontSize: "1rem", p: 1, minHeight: "60px" }}
          variant="h6"
          component="div"
        >
          {product.name}
        </Typography>

        <CardContent sx={{ flexGrow: 1, p: 1 }}>
          <Typography gutterBottom sx={{ textAlign: "left", color: "#898989" }}>
            {product.category ? product.category.name : "category"}
          </Typography>

          <Typography
            gutterBottom
            sx={{ textAlign: "left" }}
            variant="h6"
            color="text.secondary"
          >
            {`Ref: ${product.navRef}`}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
}
