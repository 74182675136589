import * as React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Offer from "../Offer";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Partner from "../Partner";
import Coming from "../Coming";

const theme = createTheme({
  typography: {
    width: "610px",
    height: "190px",
    left: "730px",
    top: "357px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "150%",
  },
});

export default function Tendance() {
  // return <Coming />;
  return (
    <ThemeProvider theme={theme}>
      <Navbar />
      <Container maxWidth="xl">
        <Grid>
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{
              mb: 1,
            }}
          >
            <Link underline="hover" color="inherit" href="/promotions">
              Promotions
            </Link>
          </Breadcrumbs>
          <Typography
            variant="h6"
            component="div"
            sx={{
              m: 2,
              ml: 0,
              fontWeight: "bold",
              color: "#2B7FA4",
            }}
          >
            Promotions
          </Typography>
        </Grid>
      </Container>

      <Container maxWidth="xxl">
        {/* <Offer /> */}
        <Grid
          sx={{
            p: 2,
            textAlign: "justify",
          }}
        >
          <Typography
            component="div"
            sx={{
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            Promotion
            <Divider
              style={{
                border: "2px solid #2B7FA4",
                backgroundColor: "#2B7FA4",
                float: "right",
                width: "75%",
                margin: "20px 0px 0px 0px",
              }}
            />
          </Typography>
          <Typography>
            tous les articles en promotion sont à la limite du stock
          </Typography>
          {/* Product list  */}
          {/* <Grid container spacing={3} mt={2}>
            {cards.map((card) => (

                <ProductItem />

            ))}
          </Grid>*/}
          <Stack
            sx={{ pt: 4 }}
            direction="row"
            spacing={2}
            justifyContent="center"
          >
            <Button variant="outlined">
              Pas de promotion actuellement , Restez à l'écoute{" "}
            </Button>
          </Stack>
        </Grid>
      </Container>

      <Partner />
      <Footer />
    </ThemeProvider>
  );
}
