import * as React from "react";
import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import logo from "../../images/logo.png";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Categories from "../Categories";
import { Popover } from "@mui/material";
import { useLocation } from "react-router-dom";
import "../Header/Header.css";

// import { Tooltip } from "@mui/material";
// import { Avatar } from "@mui/material";
// import Stack from "@mui/material/Stack";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3A3A3A",
    },
    secondary: {
      main: "#2B7FA4",
    },
  },
  typography: {
    textDecoration: "none",
    button: {
      textTransform: "none",
      fontSize: "16px",
      fontFamily: "Gilroy",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "150%",
      p: "20px",
      "&:active": {
        color: "yellow",
        borderBottom: "1px solid white",
      },
    },
  },
});

const Navbar = () => {

  const [popoverOpen, setPopoverOpen] = useState(false);
  const togglePopover = () => setPopoverOpen(!popoverOpen);
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [q, setQ] = useState("");
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      navigate(`/search?q=${q}`);
    }
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar
        position="sticky"
        className="headercss"
        style={{
          boxShadow: "none",
          background:
            location.pathname === "/"
              ? "linear-gradient(90deg, #F9F1E7 63%, #FCF8F3 60%)"
              : "white",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              noWrap
              variant="h6"
              component="a"
              href="/"
              sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
            >
              <img src={logo} alt="Home" style={{ maxWidth: 120 }} />
            </Box>
            {/* mobile */}
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem onClick={handleClick}>
                  <Typography textAlign="center">
                    Produits
                    <NavigateNextIcon
                      sx={{
                        ml: "auto",
                        verticalAlign: "middle",
                        display: "inline-block",
                      }}
                    />
                  </Typography>
                </MenuItem>
                <MenuItem onClick={() => navigate("/about")}>
                  <Typography textAlign="center">A propos</Typography>
                </MenuItem>
                <MenuItem
                  href="/promotions"
                  onClick={() => navigate("/promotions")}
                >
                  <Typography textAlign="center">Promotions</Typography>
                </MenuItem>
                <MenuItem onClick={() => navigate("/shops")}>
                  <Typography textAlign="center">Nos magazins</Typography>
                </MenuItem>
              </Menu>
              <Box
                variant="h5"
                component="a"
                sx={{
                  background: location.pathname === "/" ? "white" : "#F5F5F5",
                  m: "auto",
                  display: { xs: "flex", md: "none" },
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    display: { xs: "flex", md: "flex" },
                    backgroundColor: "#F5F5F5",
                  }}
                  mr={5}
                >
                  <IconButton
                    type="button"
                    aria-label="search"
                    onClick={() => navigate(`/search?q=${q}`)}
                  >
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    style={{
                      ml: 1,
                      width: "100%",
                      flex: 1,
                      textTransform: "none",
                      fontSize: "16px",
                      fontFamily: "Gilroy",
                    }}
                    placeholder="Rechercher un produit ..."
                  />
                </Box>

                <Box noWrap variant="h6" component="a" href="/">
                  <img
                    src={logo}
                    alt="Home"
                    style={{ maxWidth: 80 }}
                    className="logo"
                  />
                </Box>
              </Box>
            </Box>

            {/* desktop */}
            <Box
              sx={{ flexGrow: 1, ml: 3, display: { xs: "none", md: "flex" } }}
            >
              <Button
                sx={{
                  my: 3,
                  pr: "3%",
                  color:
                    location.pathname === "/products" ? "#2a7ea4" : "#3A3A3A",
                  display: "flex",
                }}
                onClick={handleClick}
                aria-describedby={id}
              >
                Produits
                {open ? <ExpandLess /> : <ExpandMore />}
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Categories onClose={handleClose} sx={{ m: 2 }} />
              </Popover>
              <Button
                href="/about"
                onClick={handleCloseNavMenu}
                sx={{
                  my: 3,
                  pr: "3%",
                  color: location.pathname === "/about" ? "#2a7ea4" : "#3A3A3A",
                  display: "block",
                }}
              >
                A Propos
              </Button>
              <Button
                href="/promotions"
                onClick={handleCloseNavMenu}
                sx={{
                  my: 3,
                  pr: "3%",
                  color:
                    location.pathname === "/promotions" ? "#2a7ea4" : "#3A3A3A",
                  display: "block",
                }}
              >
                Promotions
              </Button>
              <Button
                href="/shops"
                onClick={handleCloseNavMenu}
                sx={{
                  my: 3,
                  pr: "3%",
                  color: location.pathname === "/shops" ? "#2a7ea4" : "#3A3A3A",
                  display: "block",
                }}
              >
                Nos Magasins
              </Button>
            </Box>
            <Box
              maxWidth="500px"
              sx={{
                my: 3,

                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                backgroundColor:
                  location.pathname === "/" ? "white" : "#F5F5F5",
              }}
            >
              <IconButton
                type="button"
                aria-label="search"
                onClick={() => navigate(`/search?q=${q}`)}
              >
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{
                  width: "100%",
                  ml: 1,
                  flex: 1,
                  fontSize: "16px",
                  fontFamily: "Gilroy",
                  backgroundColor:
                    location.pathname === "/" ? "white" : "#F5F5F5",
                }}
                placeholder="Rechercher un produit"
                onChange={(e) => setQ(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
};
export default Navbar;
