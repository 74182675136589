import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../config";
import { CssBaseline, Grid, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import ProductItem from "../Products/HomeProductItem";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Partner from "../Partner";
import "./search.css";

const theme = createTheme();
const Search = () => {
  const [productsSearch, setProductsSearch] = useState([]);
  const query = useLocation().search;
	const serv = "https://dev.server.slm.com.tn"

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const res = await axios.get(`${serv}/api/products/search${query}`);
        // add search for porcelenato
        const rev = await axios.get(
          `${serv}/api/collections/search${query}`
        );
        const total = res.data.concat(rev.data);
        setProductsSearch(total);
      } catch (error) {
        console.log("noo product with this name");
      }
    };
    fetchProducts();
  }, [query]);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Navbar />
        <div className="wrapper-search">
          <Grid className={"product-list-container"} container p={3}>
            {productsSearch.length == 0 ? (
              <Grid>
                <Typography>
                  {" "}
                  Aucun produit ne correspond à votre recherche actuelle
                </Typography>
                <Typography> Veuillez essayer d'autres mots-clés</Typography>
              </Grid>
            ) : (
              productsSearch.map((product) => (
                <Grid
                  className={"product-box"}
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  p={2}
                >
                  <ProductItem key={product._id} product={product} />
                </Grid>
              ))
            )}
          </Grid>
        </div>
        <Partner />
        <Footer />
      </ThemeProvider>
    </div>
  );
};

export default Search;
