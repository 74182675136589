import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { loginFailure, loginStart, loginSuccess } from "../../redux/userSlice";
import { useNavigate } from "react-router-dom";
// import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import Paper from "@mui/material/Paper";
import { auth, provider } from "../../firebase";
import { signInWithPopup } from "firebase/auth";
import { Link } from "react-router-dom";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import logo from "../../images/logo.png";
import Img1 from "../../images/img1.jpg";
import Img3 from "../../images/img3.jpg";
import Img4 from "../../images/img4.jpg";

const items = [Img1, Img3, Img4];

const theme = createTheme();


export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(loginStart());
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      
      const res = await axios.post(
        "/api/users/login",
        { email, password },
        config
      );
      console.log("reeeeees", res);
      dispatch(loginSuccess(res.data));
      localStorage.setItem('userinfo', JSON.stringify(res.data))
      navigate("/");
    } catch (err) {
      dispatch(loginFailure());
    }
  };

  const signInWithGoogle = async () => {
    dispatch(loginStart());
    const config = {
      headers: {
          'Content-Type' : 'application/json'
      }
    }
    signInWithPopup(auth, provider)
      .then((result) => {
     
        axios
          .post("/api/users/google", {
            name: result.user.displayName,
            email: result.user.email,
            img: result.user.photoURL,
          }, config)
          .then((res) => {
            console.log(res);
            dispatch(loginSuccess(res.data));
            navigate("/");
          });
      })
      .catch((error) => {
        dispatch(loginFailure());
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundColor: "#2B7FA4",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 6,
              mx: 10,
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <Grid
              sx={{
                mb: 1,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <img
                src={logo}
                alt="Home"
              style={{ maxWidth: 100, marginRight: "10px" }}
               />
              <FormControl variant="standard" sx={{ mt: 0, width: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  English (UK)
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  label="language"
                >
                  <MenuItem value="">
                    <em>English (UK)</em>
                  </MenuItem>
                  <MenuItem value="">Francais (FR)</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Box sx={{ mt: 2, mb: 2, display: "block", textAlign: "left" }}>
              <Typography>Bienvenue</Typography>
              <Typography component="h1" variant="h5">
                Connectez-vous a votre compte
              </Typography>
            </Box>

            <Box component="form" noValidate onSubmit={handleSubmit} >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Mot de passe"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <Box
                sx={{
                  mt: 2,
                  mb: 1,
                  mx: 1,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Rappeller"
                />
                <Link to="#" variant="body2">
                  Mot de passe oublie?
                </Link>
              </Box>

              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{ mb: 2, backgroundColor: "#2B7FA4" }}
                onClick={handleSubmit}
              >
                Connecter
              </Button>

              <Typography sx={{ mb: 1, textAlign: "center", color: "#A3A3A3" }}>
                - OR -
              </Typography>
              <Box
                sx={{
                  mb: 1,
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <Button
                  sx={{ m: 1, backgroundColor: "#3781FF" }}
                  type="submit"
                  variant="contained"
                  onClick={signInWithGoogle}
                >
                  <GoogleIcon />
                  Login with Google
                </Button>
                <Button
                  sx={{ m: 1, backgroundColor: "#3B5998" }}
                  type="submit"
                  variant="contained"
                >
                  <FacebookIcon />
                  Login with Facebook
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                Vous n'avez pas de compte ?
                <Link to="/signup" variant="body2">
                  {"inscrivez-vous gratuitement aujourdhuid"}
                </Link>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
