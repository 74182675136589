import * as React from "react";
import { useState } from "react";
import { Typography, Grid, InputBase, Box, Popover } from "@mui/material";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import SendIcon from "@mui/icons-material/Send";
import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Link } from "react-router-dom";
import { Link as Liink } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Log from "../../images/logo.png";
import "./Footer.css";

const theme = createTheme({
  typography: {
    h6: {
      fontWeight: 600, // or 'bold'
      color: "#000000",
    },
  },
  a: {
    textDecoration: "none",
  },
});

const Ul = styled("ul")({
  listStyle: "none",
});

const Li = styled("li")({
  color: "#616161",
  margin: 10,
  display: "flex",
});

const Logo = styled("img")({
  maxWidth: "20%",
  maxHeight: "15%",
});

const Divider = styled("hr")({
  border: "1px solid #D8D8D8",
  margin: "3%",
});

const Footer = () => {
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <ThemeProvider theme={theme}>
      <Grid className="footer">
        <Divider
          style={{
            border: "1px solid #D8D8D8",
            backgroundColor: "#D8D8D8",
            margin: "50px 10px 0px 10px",
          }}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Ul>
              <Li>
                <Logo src={Log} href="/" />
              </Li>
              <Li>La société Lumière Mahboubine SLM crée en 1976</Li>
              <Li>
                <LocationOnIcon sx={{ mr: 1 }} /> Siège:Av. 2 Mars 1934, Cite El
                Habib Ezzahra ben Arous
              </Li>
              <Li>
                <CallIcon sx={{ mr: 1 }} /> +(216) 31336060
              </Li>
              <Li> www.slm.com.tn</Li>
            </Ul>
          </Grid>

          <Grid item xs={6} sm={6} md={3}>
            <Ul>
              <Li>
                <Typography variant="h6">Menu</Typography>
              </Li>
              <Link to="/products" style={{ textDecoration: "none" }}>
                <Li>Produits</Li>{" "}
              </Link>
              <Link to="/Promotions" style={{ textDecoration: "none" }}>
                <Li>Promotions</Li>
              </Link>

              <Link to="/About" style={{ textDecoration: "none" }}>
                <Li>A propos nous</Li>
              </Link>
            </Ul>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Ul>
              <Li>
                <Typography variant="h6">Connectez Vous</Typography>
              </Li>
              <Liink
                href="https://www.facebook.com/SLMELKATEB"
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <Li>
                  <FacebookOutlinedIcon sx={{ mr: 1, color: "#2B7FA4" }} />

                  <Typography>Facebook</Typography>
                </Li>
              </Liink>
              <Liink
                href="https://www.instagram.com/societe_lumiere_mahboubine/"
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <Li>
                  <InstagramIcon sx={{ mr: 1, color: "#2B7FA4" }} />
                  <Typography>Instagram</Typography>
                </Li>
              </Liink>
              <Li
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <EmailRoundedIcon sx={{ mr: 1, color: "#2B7FA4" }} />
                <Typography>Mail</Typography>
                <Popover
                  id="mouse-over-popover"
                  sx={{
                    pointerEvents: "none",
                  }}
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <Typography sx={{ p: 1 }}> slm@slm.com.tn</Typography>
                </Popover>
              </Li>
            </Ul>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Ul>
              <Li>
                <Typography variant="h6">Restez à jour</Typography>
              </Li>
              <Li>
                <InputBase
                  sx={{
                    backgroundColor: "#F4F5F7",
                  }}
                  placeholder=" Entrer votre Email"
                ></InputBase>
                <Box backgroundColor="#2B7FA4">
                  <SendIcon
                    style={{
                      color: "white",
                      paddingTop: "5px",
                      transform: "rotate(-25deg)",
                    }}
                  />
                </Box>
              </Li>
            </Ul>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
export default Footer;
