import * as React from "react";
import { useState } from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./Header.css";
import Img1 from "../../images/tanzania.jpg";
import Img2 from "../../images/storm.jpg";
import Img3 from "../../images/img3.jpg";
import Img4 from "../../images/touche.jpg";
import { Popover, Button } from "@mui/material";
const theme = createTheme({
  palette: {
    primary: {
      main: "#3A3A3A",
    },
    secondary: {
      main: "#2B7FA4",
    },
  },
  typography: {
    textDecoration: "none",
    button: {
      textTransform: "none",
      fontSize: "16px",
      fontFamily: "Gilroy",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "150%",
      p: "20px",
      "&:active": {
        color: "yellow",
        borderBottom: "1px solid white",
      },
    },
  },
});

const styles = {
  contact: {
    background: "#2B7FA4",
    color: "white",
    margin: "0 auto",
  },
  info: {
    display: "flex",
    fontFamily: "Gilroy",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "150%",
    paddingBottom: "16px",
    textTransform: "none",
    paddingLeft: "25px",
  },
};

const items = [
	 {
    id: 1,
    imgSrc: Img3,
    route: "/product/revetement/63f4e80dd1d7b43edae1ad22",
  },
  {
    id: 2,
    imgSrc: Img2,
    route: "/product/revetement/6459161aeb309647b26f8f1a",
  },
  {
    id: 3,
    imgSrc: Img1,
    route: "/product/revetement/6452212886dd9705da3a5e2c",
  },
  {
    id: 4,
    imgSrc: Img4,
    route: "/product/revetement/645a05e74053fa784d333c4f",
  },
  
];

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <ThemeProvider>
      <Grid container className="sliderin">
        <Grid className="headerres" sx={{ background: "#F9F1E7" }}>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              fontFamily: "Gilroy",
              fontSize: "32px",
              fontWeight: 800,
              textAlign: "center",
              padding: "5% 12% 5% 12%",
              color: "#3A3A3A;",
            }}
          >
            Des Produits de Haute Qualité Rien Que Pour Vous ! <br />
            <Button
              variant="contained"
              sx={{
                fontFamily: "Gilroy",
                background: "#2B7FA4",
                alignItems: "center",
                fontWeight: "800",
                fontSize: "20px",
                color: "#FFFFFF",
              }}
              href="/products"
            >
              Découvrez
            </Button>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            height: "auto",
          }}
        >
          <AliceCarousel
			items={items.map((item) => (
              <RouterLink to={item.route} key={item.id}>
                <img
                  className="item"
                  src={item.imgSrc}
                  height="100%"
                  width="100%"
                  alt=""
                  role="presentation"
                />
              </RouterLink>
            ))}
            className="sliderin"
            autoWidth
            innerWidth
            mouseTracking
            activeIndex={0}
            infinite
            additionalTransfrom={0}
            arrows
            touchTracking
            centerMode={false}
            fadeOutAnimation={true}
            mouseDragEnabled={true}
            
            autoPlay={true}
            autoPlayInterval={4000}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 3,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 30,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 2,
                partialVisibilityGutter: 30,
              },
            }}
            pauseOnHover
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            slidesToSlide={1}
            swipeable
          />
        </Grid>
      </Grid>
      <marquee
        className="marquee"
        behavior="scroll"
        direction="left"
        style={{ background: "#2B7FA4" }}
      >
        <Grid container spacing={2} style={styles.contact}>
          <Grid item xs={2} sm={6} md={2} style={styles.info}>
            <FacebookOutlinedIcon sx={{ mr: 1 }} />
            <Link
              href="https://www.facebook.com/SLMELKATEB"
              color="inherit"
              style={{ textDecoration: "none" }}
              target="_blank"
            >
              <Typography>Facebook</Typography>
            </Link>
          </Grid>

          <Grid item xs={2} sm={6} md={2} style={styles.info}>
            <InstagramIcon sx={{ mr: 1 }} />
            <Link
              href="https://www.instagram.com/societe_lumiere_mahboubine/"
              color="inherit"
              style={{ textDecoration: "none" }}
              target="_blank"
            >
              <Typography>Instagram</Typography>
            </Link>
          </Grid>

          <Grid
            item
            xs={2}
            sm={6}
            md={2}
            style={styles.info}
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <EmailRoundedIcon sx={{ mr: 1 }} />
            <Typography>Mail</Typography>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography sx={{ p: 1 }}> slm@slm.com.tn</Typography>
            </Popover>
          </Grid>
          <Grid
            item
            xs={1}
            sm={6}
            md={1}
            style={styles.info}
            sx={{ fontSize: "bold" }}
          >
            |
          </Grid>
          <Grid item xs={5} sm={6} md={5} style={styles.info}>
            <LocationOnIcon sx={{ mr: 1 }} /> Cite El Habib Ezzahra
            <CallIcon sx={{ mr: 1, ml: 5 }} /> 31 336 060
          </Grid>
        </Grid>
      </marquee>
    </ThemeProvider>
  );
};
export default Header;
