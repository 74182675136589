import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

import FormGroup from "@mui/material/FormGroup";
import InputBase from "@mui/material/InputBase";
import TextField from "@mui/material/TextField";
import { Box, Grid, Modal } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
// import {
//   getCategoryStart,
//   getCategorySuccess,
//   getCategoryFailure,
// } from "./../redux/categorySlice";

// import {
//   fetchSubCategoryStart,
//   fetchSubCategorySucces,
//   fetchSubCategoryFailure,
// } from "../redux/productsSlice";
import {
  addRevetProductStart,
  addRevetProductSuccess,
  addRevetProductFailure,
} from "../redux/revetProductSlice";

import Img1 from "../images/img1.jpg";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#2B7FA4",
    },
  },
  typography: {
    color: "#737B7D",
    textDecoration: "none",
    button: {
      textTransform: "none",
      fontSize: "1rem",
      backgroundColor: " #2B7FA4",
    },
  },
});
const styles = {
  paperContainer: {
    height: "200px",
    backgroundImage: `url(${Img1})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    width: "400px",
    textAlign: "center",
  },
  imageUpload: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: " -25px 0 0 -25px",
  },
};

export default function AddRevetProduct() {
  const [navRef, setNavRef] = useState("");
  const [name, setName] = useState("");
  const [brand, setBrand] = useState("");
  const [brillant, setBrillant] = useState(false);
  const [dimensions, setDimensions] = useState(false);
  const [mat, setMat] = useState(false);
  const [interieur, setInterieur] = useState(false);
  const [exterieur, setExterieur] = useState(false);
  const [bodycolor, setBodyColor] = useState("");
  const [antiderapant, setAntiderapant] = useState(false);
  const [sol, setSol] = useState(false);
  const [mur, setMur] = useState(false);
  const [rect, setRect] = useState(false);
  const [lapato, setLapato] = useState(false);
  const [grès, setGrès] = useState(false);
  const [category, setCategory] = useState("634d784a4ef4b43937e9563e");
  const [subCategory, setSubCategory] = useState("");

  const [colProduct, setColProduct] = useState();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  const uploadFileHandler = async (e) => {
    const files = e.target.files;
    const formData = new FormData();
    for (const file of files) {
      formData.append("files", file);
    }

    setLoading(true);
    try {
      const config = {
        Headers: {
          "Content-Type": "multipart/form/data",
        },
      };

      const { data } = await axios.post("/api/upload", formData, config);
      console.log("imaaaages", data);
      setImages(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const clear = () => {
    setNavRef("");
    setName("");
    setBrand("");
    // setBrillant();
    // setAntiderapant();
    // setBodyColor();
    // setCategory();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(addRevetProductStart());
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        "/api/revetproduct",
        {
          navRef,
          name,
          bodycolor,
          images,
          dimensions,
          antiderapant,
          interieur,
          exterieur,
          mur,
          brillant,
          brand,
          sol,
          rect,
          lapato,
          mat,
          grès,
          category,
          subCategory,
        },
        config
      );
      dispatch(addRevetProductSuccess(data));
    } catch (error) {
      dispatch(addRevetProductFailure());
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Typography variant="h4" sx={{ color: "#737B7D" }} m={2}>
        Ajouter un produit revêtement
      </Typography>
      <Grid>
        <Box
          component="form"
          sx={{
            ml: 2,
          }}
          spacing={2}
          noValidate
          autoComplete="on"
        >
          <Grid m={2}>
            <TextField
              required
              id="outlined-multiline-flexible"
              label="Reference Navision"
              placeholder="Reference Navision"
              // value=""
              color="secondary"
              maxRows={5}
              onChange={(e) => setNavRef(e.target.value)}
            />

            <TextField
              required
              id="outlined-multiline-flexible"
              label="Designation Produit revêtement"
              placeholder="Designation Produit revêtement"
              // value=""
              color="secondary"
              maxRows={5}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid m={2}>
            <Typography variant="h6" sx={{ color: "#737B7D" }}>
              caractéristique
            </Typography>
            <Box
              component="form"
              sx={{
                ml: 2,
              }}
              spacing={2}
              noValidate
              autoComplete="on"
            >
              <Grid style={{ display: "flex" }}>
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  label="Mat"
                  onChange={(e) => setMat(true)}
                />
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  label="Brillant "
                  onChange={(e) => setBrillant(true)}
                />

                <FormControlLabel
                  control={<Checkbox size="small" />}
                  label="Interieur "
                  onChange={(e) => setInterieur(true)}
                />
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  label="Exterieur"
                  onChange={(e) => setExterieur(true)}
                />
              </Grid>
              <Grid style={{ display: "flex" }}>
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  label="Antidérapant"
                  onChange={(e) => setAntiderapant(true)}
                />

                <FormControlLabel
                  control={<Checkbox size="small" />}
                  label="Sol"
                  onChange={(e) => setSol(true)}
                />
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  label="Mur"
                  onChange={(e) => setMur(true)}
                />
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  label="Rectifié"
                  onChange={(e) => setRect(true)}
                />
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  label="Lappato"
                  onChange={(e) => setLapato(true)}
                />
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  label="Grès"
                  onChange={(e) => setGrès(true)}
                />
              </Grid>
            </Box>
          </Grid>
          <Grid m={2}>
            <TextField
              required
              id="outlined-multiline-flexible"
              label="Dimensions"
              placeholder="Dimensions"
              // value=""
              color="secondary"
              maxRows={5}
              onChange={(e) => setDimensions(e.target.value)}
            />
          </Grid>
          <FormControl fullWidth>
            <InputLabel> couleur de pâte </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="revêtement ?"
              onChange={(e) => setBodyColor(e.target.value)}
            >
              <MenuItem value={"Blanc"}> Blanc</MenuItem>
              <MenuItem value={"rouge"}> Rouge</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel> Marque</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="revêtement ?"
              onChange={(e) => setBrand(e.target.value)}
            >
              <MenuItem value={"Azteca"}> Azteca</MenuItem>
              <MenuItem value={"Inalco"}> Inalco</MenuItem>
              <MenuItem value={"Casalgrande Padana"}>
                {" "}
                Casalgrande Padana
              </MenuItem>
              <MenuItem value={"Azulev"}> Azulev</MenuItem>
              <MenuItem value={"Azuvi"}> Azuvi</MenuItem>
              <MenuItem value={"STN"}> STN</MenuItem>
              <MenuItem value={"Alcor"}> Alcor</MenuItem>
              <MenuItem value={"vives"}> Vives</MenuItem>
              <MenuItem value={"Ktl"}> KTL Ceramica</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel> sous Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="revêtement ?"
              onChange={(e) => setSubCategory(e.target.value)}
            >
              <MenuItem value={"634d841383008c2afea5535b"}> Faience</MenuItem>
              <MenuItem value={"634d842f83008c2afea5535e"}> Grès</MenuItem>
              <MenuItem value={"637397c0c9c8a3db657f9fb3"}>
                glass mosaique
              </MenuItem>
              <MenuItem value={"637397d4c9c8a3db657f9fb6"}>
                terre cuite
              </MenuItem>
            </Select>
          </FormControl>

          <Typography variant="h6" sx={{ color: "#737B7D" }}>
            Ajouter photos de la collections
          </Typography>

          <Grid m={2} style={styles.paperContainer}>
            <Button
              variant="contained"
              component="label"
              color="secondary"
              style={styles.imageUpload}
            >
              Ajouter
              {/* <input hidden accept="image/*" multiple type="file" /> */}
              <input
                //  hidden
                //  accept="image/*"
                multiple
                type="file"
                // onChange={(e)=> setImages(e.target.files)}
                onChange={uploadFileHandler}
              />
            </Button>
          </Grid>
        </Box>
        <Button
          m={2}
          variant="contained"
          component="button"
          color="secondary"
          onClick={handleSubmit}
        >
          Validation
        </Button>
      </Grid>
      {/* <Modal isOpen={showModal} onRequestClose={handleCloseModal}>
        <h1>Product added successfully!</h1>
        <button onClick={handleCloseModal}>Close</button>
      </Modal> */}
    </ThemeProvider>
  );
}
