import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Pagination } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Breadcrumbs from "@mui/material/Breadcrumbs";

import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import Offer from "../Offer";
import Filter from "../Filters";

import "./Slider.css";
import {
  fetchProductBySubCategoryStart,
  fetchProductBySubCategorySuccess,
  fetchProductBySubCategoryFailure,
} from "../../redux/productsSlice";
import {
  productListStart,
  productListSuccess,
  productListFailure,
} from "../../redux/productSlice";
import axios from "axios";
import { API_BASE_URL } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import ProductItem from "../../components/Products/HomeProductItem";
import Coming from "../Coming";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#2B7FA4",
    },
  },
  typography: {
    textDecoration: "none",
    button: {
      textTransform: "none",
      fontSize: "1rem",
    },
	
  },
});
export default function AllProducts() {
  const [view, setView] = React.useState("list");
  const [page, setPage] = React.useState(1);
  const { Subproducts } = useSelector((state) => state.products);
  const { pages } = useSelector((state) => state.products);
  const ll = useLocation();
  const sub = useLocation().pathname.split("/")[2];
  const dispatch = useDispatch();
  // prépare deploy
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  ///////////
  const handleChangeView = (event, nextView) => {
    setView(nextView);
  };

  const handlPage = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (
        sub === "634d841383008c2afea5535b" ||
        sub === "634d842f83008c2afea5535e"
      ) {
        try {
          const result = await axiosInstance.get(
            `/collections/category?sub=${sub}&pageNumber=${page}`
          );

          dispatch(fetchProductBySubCategorySuccess(result.data));
        } catch (error) {}
      } else if (ll.pathname === "/products") {
        try {
          const result = await axiosInstance.get(`/products/randomp`);
          dispatch(fetchProductBySubCategorySuccess(result.data));
        } catch (err) {
          dispatch(productListFailure());
        }
      } else {
        try {
          const result = await axiosInstance.get(
            `/products/?subCategory=${sub}&pageNumber=${page}`
          );
          dispatch(fetchProductBySubCategorySuccess(result.data));
        } catch (error) {}
      }
    };
    fetchData();
  }, [sub, dispatch, ll, page]);
  return (
    <ThemeProvider theme={theme}>
      <Navbar />
      <Container maxWidth="l">
        <Grid>
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{
              mb: 1,
            }}
          ></Breadcrumbs>
        </Grid>
      </Container>
      <Container maxWidth="l">
        <Grid container sx={{ pl: 2 }}>
          <Grid item xs={12} sm={6} md={3} sx={{ backgroundColor: "#F6F8FB" }}>
            <Filter />
          </Grid>
          <Grid item xs={12} sm={6} md={9}>
            {/* <Offer /> */}
            {/* filter bar */}
            <Grid item xs={12} sm={4} md={4}>
              <ToggleButtonGroup
                orientation="vertical"
                value={view}
                exclusive
                onChange={handleChangeView}
                sx={{ display: "block" }}
              >
                <ToggleButton
                  value="list"
                  aria-label="list"
                  sx={{ borderRadius: 0, color: "#2b7fa4", m: 1 }}
                >
                  <ViewListIcon />
                </ToggleButton>
                <ToggleButton
                  value="module"
                  aria-label="module"
                  sx={{
                    borderRadius: 0,
                    color: "#2b7fa4",
                    borderTop: "1px solid rgba(0, 0, 0, 0.08) !important",
                  }}
                >
                  <ViewModuleIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            <Grid
              className={"products-list-container"}
              container
              m={3}
              sx={{ pr: 2 }}
            >
              <Grid
                className={"products-list-content"}
                container
                style={{ margin: "auto" }}
              >
                {Subproducts.length === 0 ? (
                  <Coming />
                ) : (
                  Subproducts.map((product, key) => (
                    <Grid
                      className={"product-box"}
                      item
                      xs={12}
                      sm={4}
                      md={4}
                      p={2}
                      key={key}
                    >
                      <ProductItem product={product} />
                    </Grid>
                  ))
                )}
              </Grid>

              <Grid sx={{ m: "0 auto", pt: 10 }}>
                <Pagination
                  count={pages}
                  page={page}
                  onChange={handlPage}
                  variant="outlined"
                  shape="rounded"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </ThemeProvider>
  );
}
