import React from "react";
import { Typography } from "@mui/material";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import "../../App.css";
import "./feature.css";

const styles = {
  text: {
    textTransform: "none",
    textAlign: "center",
    fontSize: "20px",
    fontFamily: "Gilroy",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "150%",
    color: "#3A3A3A",
    display: "flex",
    flexDirection: "inline",
    textAlign: "left",
    margin: "auto 0",
  },
};
export default function Feature() {
  return (
    <Container maxWidth="xl">
      <Grid container p={5} className={"feature-container"}>
        <Grid item xs={12} sm={6} md={3}>
          <Box style={styles.text} className="feature">
            <EmojiEventsOutlinedIcon sx={{ mx: 2, height: 50, width: 50 }} />
            <Typography style={styles.text}>Haute Qualité </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Box style={styles.text} className="feature">
            <VerifiedOutlinedIcon sx={{ mx: 2, height: 50, width: 50 }} />
            <Box>
              <Typography style={styles.text}>Garantie </Typography>
              <Typography sx={{ mt: "-25px", color: " #898989" }}>
                <br />
                Plus de 2 ans
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Box style={styles.text} className="feature">
            <LocalShippingOutlinedIcon sx={{ mx: 2, height: 50, width: 50 }} />
            <Typography style={styles.text}>Livraison gratuite</Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Box style={styles.text} className="feature">
            <SupportAgentIcon sx={{ mx: 2, height: 50, width: 50 }} />
            <Box>
              <Typography style={styles.text}>Service Client </Typography>
              <Typography sx={{ mt: "-25px", color: " #898989" }}>
                <br />
                31 336 060
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
