import * as React from "react";
import "./Inspiration.css";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Img1 from "../../images/ins/insp1.jpg";
import storm from "../../images/ins/storm.jpg";
import balkan from "../../images/ins/balkan.jpg";
import iseo from "../../images/ins/iseo.jpg";
import touche from "../../images/ins/touche.jpg";
import porte from "../../images/ins/porte.jpg";
import meuble from "../../images/ins/meuble.jpg";

import Img2 from "../../images/ins/insp2.jpg";
import Img3 from "../../images/ins/insp2.png";
import Img4 from "../../images/img1.jpg";
import Img5 from "../../images/img2.jpg";

const theme = createTheme();

const Inspiration = () => {
  const imageClassName = ["img-small", "img-medium", "img-large"];

  return (
    <ThemeProvider theme={theme}>
      <Typography
        variant="h4"
        component="div"
        sx={{
          my: 4,
          mx: 10,
          fontWeight: "bold",
        }}
        textAlign="center"
      >
        Inspirez-Vous
      </Typography>
      <Box fullWidth>
        <ImageList
          className={"inspiration-gallery-container"}
          variant="woven"
          cols={5}
          sx={{}}
        >
          {itemData.map((item) => (
            <ImageListItem
              className={`card ${
                imageClassName[
                  Math.floor(Math.random() * imageClassName.length)
                ]
              }`}
              key={item.img}
              sx={{ m: 1 }}
            >
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                loading="lazy"
                className="product-image"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </ThemeProvider>
  );
};
export default Inspiration;
const itemData = [
  {
    img: storm,
    title: "Blinds",
  },
  {
    img: iseo,
    title: "Bed",
  },
  {
    img: balkan,
    title: "Blinds",
  },
  {
    img: touche,
    title: "Chairs",
  },
  {
    img: Img3,
    title: "Kitchen",
  },
  {
    img: meuble,
    title: "Bed",
  },

  {
    img: Img2,
    title: "Sink",
  },
  {
    img: Img3,
    title: "Kitchen",
  },
  {
    img: porte,
    title: "Blinds",
  },
  {
    img: Img4,
    title: "Blinds",
  },
];
