import React from "react";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Navbar from "../Navbar/Navbar";
import Header from "../Header/Header";
import Feature from "../Feature";
import ProductList from "../Products/HomeProductList";
import TendanceSlide from "../Tendance/TendanceSlide";
import Inspiration from "../Inspiration/Inspiration";
import Partner from "../Partner";
import Footer from "../Footer/Footer";

const theme = createTheme();

const Home = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Navbar />
      <Header />
      <Feature />
      <ProductList />
      <Inspiration />
      <Partner />
      <Footer />
    </ThemeProvider>
  );
};

export default Home;
