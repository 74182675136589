import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categories: [],
  subcategories: [],
  loading: false,
  error: false,
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    getCategoryStart: (state) => {
      state.loading = true;
    },
    getCategorySuccess: (state, action) => {
      state.loading = false;
      state.categories = action.payload;
    },
    getCategoryFailure: (state) => {
      state.loading = false;
    },
    getSubCategoryStart: (state) => {
      state.loading = true;
    },
    getSubCategorySuccess: (state, action) => {
      state.loading = false;
      state.subcategories = action.payload;
    },
    getSubCategoryFailure: (state) => {
      state.loading = false;
    },
  },
});
export const {
  getCategoryStart,
  getCategorySuccess,
  getCategoryFailure,
  getSubCategoryStart,
  getSubCategorySuccess,
  getSubCategoryFailure,
} = categorySlice.actions;
export default categorySlice.reducer;
