import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  groupedSubCat: [],
};

export const subcategoriesSlice = createSlice({
  name: "subcategories",
  initialState,
  reducers: {
    getgroupedSubCatStart: (state) => {
      state.loading = true;
    },
    getgroupedSubCatSuccess: (state, action) => {
      state.loading = false;
      state.groupedSubCat = action.payload;
    },
    getgroupedSubCatFailure: (state) => {
      state.loading = false;
    },
    // it is just to pull an other time
  },
});

export const {
  getgroupedSubCatStart,
  getgroupedSubCatSuccess,
  getgroupedSubCatFailure,
} = subcategoriesSlice.actions;
export default subcategoriesSlice.reducer;
