import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import {
  productListStart,
  productListSuccess,
  productListFailure,
} from "../../redux/productSlice";
import { useNavigate, Link } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ProductItem from "./HomeProductItem";

const theme = createTheme();
export default function ProductList() {
  const [productList, setproductList] = useState([]);
  const dispatch = useDispatch();

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  useEffect(() => {
    const fetchproductList = async () => {
      dispatch(productListStart());
      try {
        // "proxy": "http://89.116.228.50:8080/",
        // "proxy": "http://127.0.0.1:5000/"
        const res = await axios.get("https://dev.server.slm.com.tn/api/products/random");
       const rev = await axios.get("https://dev.server.slm.com.tn/api/collections/random");
       //const res = await axiosInstance.get("/products/random");
       //const rev = await axiosInstance.get("/collections/random");
        const total = res.data.concat(rev.data.randomCollections);
        dispatch(productListSuccess(res.data));
        // juste temporary solution
        for (let i = total.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [total[i], total[j]] = [total[j], total[i]];
        }
        // tel now
        setproductList(total);
      } catch (err) {
        dispatch(productListFailure());
      }
    };
    fetchproductList();
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="l">
        <Box sx={{ m: 5 }}>
          <Typography
            variant="h4"
            component="div"
            sx={{
              fontWeight: "bold",
            }}
            textAlign="center"
          >
            Nos Produits
          </Typography>
        </Box>
        {/* Product list  */}
        <Grid className={"product-list-container"} container p={3}>
          {productList.map((product, key) => (
            <Grid className={"product-box"} item xs={12} sm={4} md={3} p={2}>
              <ProductItem key={key} product={product} />
            </Grid>
          ))}
        </Grid>
        <Stack
          sx={{ my: 4, mx: 10 }}
          direction="row"
          spacing={2}
          justifyContent="center"
        >
          <Link to="/products">
            <Button variant="outlined" sx={{ textDecoration: "none" }}>
              Voir Plus
            </Button>
          </Link>
        </Stack>
      </Container>
    </ThemeProvider>
  );
}
