import * as React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const theme = createTheme();
//function that will import all partner images
function importAll(r) {
  let images = [];
  r.keys().map((item, index) => {
    images.push(r(item));
  });
  return images;
}

const images = importAll(
  require.context("../images/logos", false, /\.(png|jpe?g|svg)$/)
);

export default function Partner() {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl">
        <Typography
          variant="h4"
          component="div"
          sx={{
            my: 4,
            mx: 10,
            fontWeight: "bold",
            color: "#3A3A3A",
          }}
          textAlign="center"
        >
          Nos Partenaires
        </Typography>
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          autoPlay
          partialVisible
          autoPlaySpeed={2000}
          centerMode={false}
          className=""
          containerClass="container"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 3,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 2,
              partialVisibilityGutter: 30,
            },
          }}
          rewind
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={2}
          swipeable
        >
          {images.map((image, key) => (
            <img
              alt="product image"
              src={image}
              height="150em"
              width="200em"
            ></img>
          ))}
        </Carousel>
      </Container>
    </ThemeProvider>
  );
}
