import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  collectionAdded: {},
  singlCollection: {},
  collectionsCat: [],
  loading: false,
  error: false,
};

export const collectionSlice = createSlice({
  name: "collection",
  initialState,
  reducers: {
    addCollectionStart: (state) => {
      state.loading = true;
    },
    addCollectionSuccess: (state, action) => {
      state.loading = false;
      state.collectionAdded = action.payload;
    },
    addCollectionFailure: (state) => {
      state.loading = false;
    },
    getCollectionStart: (state) => {
      state.loading = true;
    },
    getCollectionSuccess: (state, action) => {
      state.loading = false;
      state.singlCollection = action.payload;
    },
    getCollectionFailure: (state) => {
      state.loading = false;
    },
    getCollectionsCatstart: (state) => {
      state.loading = true;
    },
    getCollectionsCatSuccess: (state, action) => {
      state.loading = false;
      state.collectionsCat = action.payload;
    },
    getCollectionsCatFailure: (state) => {
      state.loading = false;
    },
  },
});
export const {
  addCollectionStart,
  addCollectionSuccess,
  addCollectionFailure,
  getCollectionStart,
  getCollectionSuccess,
  getCollectionFailure,
} = collectionSlice.actions;
export default collectionSlice.reducer;
