import * as React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

import "../App.css"

const theme = createTheme({
  spacing: 8,
  typography: {
    fontFamily: "Gilroy",
    align: "center",
    h4: {
      color: "#FFFFFF",
    },
  },
});

export default function Offer() {
  return (
    <ThemeProvider theme={theme}>
      <Grid
        className="offer" 
        conatiner
        ml={2}
        p={3}
        sx={{ display: "flex", backgroundColor: "#2B7FA4" }}
      >

        <Grid item xs={12} sm={6} md={10}>
          <Typography variant="h4" component="h4">
            {/* <b>-20% </b> */}
            &nbsp;Des Produits de Haute
            Qualité Rien Que Pour Vous!
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={2} sx={{    margin: "0 auto"}}>
          <Button
            variant="contained"
            style={{
              color: "#2B7FA4",
              backgroundColor: "#FFFFFF",
              fontFamily: "Poppins",
              float: "right",
              borderRadius: 0,
            }}
          >
            Check offer
          </Button>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
