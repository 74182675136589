import * as React from "react";
import axios from "axios";
import { API_BASE_URL } from "../../config";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
  Grid,
  Typography,
  Button,
  Box,
  TextField,
  Stack,
  ButtonGroup,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import Carousel from "react-multi-carousel";
import Rating from "@mui/material/Rating";
import CreateIcon from "@mui/icons-material/Create";
import Navbar from "../Navbar/Navbar";
import "react-multi-carousel/lib/styles.css";
import Footer from "../Footer/Footer";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  getCollectionStart,
  getCollectionSuccess,
  getCollectionFailure,
} from "../../redux/collectionSlice";

import { Container } from "@mui/system";
import { useEffect } from "react";
import "./Slider.css";
const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#2B7FA4",
    },
  },
  spacing: 8,
  typography: {
    fontFamily: "Gilroy",
    align: "center",
    textDecoration: "none",
    button: {
      textTransform: "none",
      fontSize: "1rem",
    },
    h4: {
      fontFamily: "Gilroy",
      color: "#000000",
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "24px",
      lineHeight: "170%",
    },
    h5: {
      fontFamily: "Gilroy",
      color: "#737B7D",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "170%",
      margin: "0px 0px 15px 0px",
    },
    h6: {
      fontFamily: "Inter",
      color: "#737B7D",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "170%",
      margin: "0px 0px 10px 0px",
    },
  },
});

const Imgstyle = {
  display: "block",
  objectFit: "fill",
  margin: "auto",
  width: "100%",
  height: "100%",
};
export default function ProductRevet() {
  const serv = "https://dev.server.slm.com.tn";
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  const dispatch = useDispatch();
  dispatch(getCollectionStart);
  const collectionId = useLocation().pathname.split("/")[3];
	
  const [products, setProducts] = useState([]);
  const { singlCollection } = useSelector((state) => state.collection);
  const [images, setImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedProd, setSelectedProd] = useState({});
  const [singlImage, setSinglImage] = useState("");

  const [carac, setCarac] = React.useState("");

  useEffect(() => {
    const fetchCollection = async () => {
      try {
        const res = await axiosInstance.get(
          `/collections/find/${collectionId}`
        );
        dispatch(getCollectionSuccess(res.data[0]));
        setImages(res.data[0].images);

        setProducts(res.data[0].products);
      } catch (error) {}
    };
    fetchCollection();
  }, [collectionId, dispatch]);

  const handleOpen = (rev) => {
    setOpen(true);
    setCarac(rev.carac);
  };
  const handleClose = (prod) => {
    setOpen(false);
    setSelectedProd({});
    setSinglImage("");
  };

  //here to get all images of the articles as custom dots
  const CustomDot = ({ index, onClick, active }) => {
    return (
      <li style={{ padding: "5px" }}>
        {" "}
        <img
          alt="image"
          onClick={(e) => {
            onClick();
            e.preventDefault();
          }}
          src={serv.concat(React.Children.toArray(images)[index])}
          //src={React.Children.toArray(images)[index]}
          height="100em"
          width="100em"
          style={{ margin: "auto 0", position: "relative", width: "90%" }}
        ></img>
      </li>
    );
  };

  const handleClickOpen = (prod) => {
    setOpen(true);
    setSelectedProd(prod);
    setSinglImage(prod.images[0]);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl">
        <Navbar />
      </Container>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={12} sm={6} md={6} sx={{ position: "relative" }}>
            <Carousel
              customDot={<CustomDot index={0} />}
              dotListClass="dotsRe"
              additionalTransfrom={0}
              arrows
              autoPlaySpeed={3000}
              centerMode={false}
              className=""
              containerClass="container"
              draggable
              focusOnSelect={false}
              infinite
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024,
                  },
                  items: 1,
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0,
                  },
                  items: 1,
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 464,
                  },
                  items: 1,
                },
              }}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              showDots
              sliderClass=""
              slidesToSlide={1}
              swipeable
            >
              {/* here to render product images */}
              {images.map((image, key) => (
                <img
                  src={serv.concat(image)}
                  //src={image}
                  style={Imgstyle}
                  key={key}
                  alt="img"
                ></img>
              ))}
            </Carousel>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Grid sx={{ ml: 5, align: "center" }}>
              <Typography variant="h5">{singlCollection.brand}</Typography>
              <Typography variant="h4">{singlCollection.name}</Typography>
              <Typography variant="h6">Vendu par SLM</Typography>
              <Grid container className="rating" sx={{ display: "flex" }}>
                <Rating
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  name="size-large"
                  defaultValue={3}
                  size="small"
                />
                <Typography
                  item
                  xs={12}
                  sm={6}
                  md={8}
                  sx={{ ml: "50px", color: "#2B7FA4", display: "flex" }}
                >
                  <CreateIcon sx={{ transform: "rotateY(180deg)" }} />
                  &nbsp; Donnez votre avis{" "}
                </Typography>
              </Grid>
              <Typography variant="h6">Livraison en 48 heures</Typography>
              <Typography variant="h6" sx={{ color: "#00BC13" }}>
                Livraison Sur toute la Tunisie
              </Typography>
              <Button
                type="submit"
                variant="contained"
                sx={{ backgroundColor: "#2B7FA4" }}
                disabled
              >
                Ajouter au panier
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* description */}
        <Grid container sx={{ mx: 3 }}>
          <Grid item xs={12} sm={8} md={8}>
            <Grid
              sx={{
                m: 4,
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  textAlign: "left",
                  fontWeight: "bold",
                  color: "#2B7FA4",
                  borderRadius: 0,
                  border: "none",
                  borderBottom: 2,
                }}
              >
                DESCRIPTION
              </Button>
              <Button
                variant="outlined"
                sx={{
                  textAlign: "left",
                  fontWeight: "bold",
                  color: "#2B7FA4",
                  borderRadius: 0,
                  border: "none",
                  borderBottom: "2 #DADADA",
                }}
              >
                AVIS
              </Button>

              <Typography sx={{ mt: 5 }}>
                {singlCollection.description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{ m: 4 }}>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            sx={{ width: "50%", margin: "0 auto" }}
          >
            <Box
              max-width={500}
              bgcolor={"background.default"}
              color={"text.primary"}
              sx={{
                p: 2,
              }}
            >
              <Typography variant="h6" color="gray" textAlign="center">
                {selectedProd.name}
              </Typography>
              <Grid sx={{ height: "200px" }}>
                <img
                  src={serv.concat(singlImage)}
                  //src={singlImage}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "cover",
                  }}
                  alt="img1"
                ></img>
              </Grid>
              <Typography> Caracteristiques techniques </Typography>
              <Typography>Dimensions:{selectedProd.dimensions}</Typography>
              <Typography>
                couleur de patte : {selectedProd.bodycolor}
              </Typography>
              {selectedProd.mat == true ? (
                <Typography> mat</Typography>
              ) : selectedProd.brillant == true ? (
                <Typography>Brillant </Typography>
              ) : (
                <Typography> lappato</Typography>
              )}
              {selectedProd.interieur == true ? (
                <Typography> Interieur</Typography>
              ) : (
                <Typography>Exterieur</Typography>
              )}
              {selectedProd.mur == true && selectedProd.sol == true ? (
                <Typography> Sol et mur </Typography>
              ) : selectedProd == true ? (
                <Typography> Sol </Typography>
              ) : (
                <Typography>Mur</Typography>
              )}
              <Typography></Typography>
            </Box>
          </Modal>
          {products.map((prod, key) => (
            <Grid
              item
              xs={12}
              sm={3}
              md={6}
              key={key}
              onClick={() => handleClickOpen(prod)}
            >
              <Grid sx={{ pr: "10%" }}>
                <Grid sx={{ height: "200px" }}>
                  <img
                    src={serv.concat(prod.images[0])}
                    //src={prod.images[0]}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "cover",
                    }}
                    alt="img1"
                  ></img>
                </Grid>
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  {prod.name}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  {prod.navRef}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Footer />
    </ThemeProvider>
  );
}
