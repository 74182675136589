import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../config";
import { Grid, Typography, Button } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import Carousel from "react-multi-carousel";
import Rating from "@mui/material/Rating";
import CreateIcon from "@mui/icons-material/Create";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import "react-multi-carousel/lib/styles.css";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import Link from "@mui/material/Link";
import { Container } from "@mui/system";
import {
  fetchStart,
  fetchSuccess,
  fetchFailure,
} from "../../redux/productsSlice";

// all style here
import "./Slider.css";
import {
  getCategoryFailure,
  getCategoryStart,
  getCategorySuccess,
  getSubCategoryFailure,
  getSubCategoryStart,
  getSubCategorySuccess,
} from "../../redux/categorySlice";
const theme = createTheme({
  spacing: 8,
  typography: {
    fontFamily: "Gilroy",
    align: "center",
    h4: {
      fontFamily: "Gilroy",
      color: "#000000",
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "24px",
      lineHeight: "170%",
    },
    h5: {
      fontFamily: "Gilroy",
      color: "#737B7D",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "170%",
      margin: "0px 0px 15px 0px",
    },
    h6: {
      fontFamily: "Inter",
      color: "#737B7D",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "170%",
      margin: "0px 0px 10px 0px",
    },
  },
});

const Imgstyle = {
  display: "block",
  margin: "10% auto",
  maxWidth: "100%",
  maxHeight: "50%",
};

const ProductDesc = () => {
  const serv = "https://dev.server.slm.com.tn";
  const dispatch = useDispatch();
  dispatch(fetchStart);
  const { categories } = useSelector((state) => state.category);
  const { singleProduct } = useSelector((state) => state.products);
  const [images, setImages] = useState([]);
  const path = useLocation().pathname.split("/")[2];
 
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productRes = await axiosInstance.get(`/products/find/${path}`);
        dispatch(fetchSuccess(productRes.data));
        setImages(productRes.data.images);
      } catch (error) {}
    };
    fetchData();
  }, []);
  useEffect(() => {
    const getAllcategory = async () => {
      dispatch(getCategoryStart);
      try {
        // category use selector not use state don't forget
        const res = await axiosInstance.get(`/category`);
        dispatch(getCategorySuccess(res.data));
      } catch (error) {
        dispatch(getCategoryFailure());
      }
    };
    getAllcategory();
  }, []);

  useEffect(() => {
    const getAllSubCategory = async () => {
      dispatch(getSubCategoryStart);
      try {
        // category use selector not use state don't forget
        const res = await axiosInstance.get(`/subCategory/grouped`);
        dispatch(getSubCategorySuccess(res.data));
      } catch (error) {
        dispatch(getSubCategoryFailure());
      }
    };
    getAllSubCategory();
  }, []);

  const handleGetName = (id) => {
    const res = categories.filter((cat) => cat._id === id);
    const name = res[0].name;
    return name;
  };
  //here to get all images of the articles as custom dots
  const CustomDot = ({ index, onClick }) => {
    return (
      <li style={{ border: "2px solid #2a7ea4", padding: "20px" }}>
        <img
          alt="image"
          onClick={(e) => {
            onClick();
            e.preventDefault();
          }}
          src={serv.concat(React.Children.toArray(images)[index])}
          //src={React.Children.toArray(images)[index]}
          height="80em"
          width="100em"
          style={{ margin: "auto 0" }}
        />
      </li>
    );
  };
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl">
        <Navbar />
      </Container>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={12} sm={6} md={6} sx={{ position: "relative" }}>
            {singleProduct && (
              <Carousel
                customDot={<CustomDot index={0} />}
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container"
                dotListClass="dots"
                draggable
                focusOnSelect={false}
                infinite
                itemClass="ItemImage"
                keyBoardControl
                minimumTouchDrag={80}
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024,
                    },
                    items: 1,
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0,
                    },
                    items: 1,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464,
                    },
                    items: 1,
                  },
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots
                slidesToSlide={1}
                swipeable
              >
                {images.map((image, key) => (
                  <img src={serv.concat(image)} style={Imgstyle} key={key} />
                  //<img src={image} style={Imgstyle} key={key} />
                ))}
              </Carousel>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Grid
              className={"product-details-container"}
              sx={{ ml: 10, mt: 4, align: "center" }}
            >
              <Typography variant="h5">
                {singleProduct.brand} | Référence. {singleProduct.navRef}
              </Typography>
              <Typography variant="h4">{singleProduct.name}</Typography>
              <Typography variant="h6">Vendu par SLM</Typography>
              <Grid container className="rating" sx={{ display: "flex" }}>
                <Rating
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  name="size-large"
                  defaultValue={3}
                  size="small"
                />
                <Typography
                  item
                  xs={12}
                  sm={6}
                  md={8}
                  sx={{ ml: "50px", color: "#2B7FA4", display: "flex" }}
                >
                  <CreateIcon sx={{ transform: "rotateY(180deg)" }} />
                  &nbsp; Donnez votre avis{" "}
                </Typography>
              </Grid>
              <Typography sx={{ mb: 3, color: "#C4C4C4" }}></Typography>
              <Typography variant="h6">Livraison en 48 heures</Typography>
              <Typography variant="h6" sx={{ color: "#00BC13" }}>
                Livraison Sur toute la Tunisie
              </Typography>
              <Button
                type="submit"
                variant="contained"
                sx={{ backgroundColor: "#2B7FA4" }}
                disabled
              >
                Ajouter au panier
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          className={"product-details-content"}
          container
          sx={{
            mx: 3,
            my: 10,
          }}
        >
          <Grid className={"product-details"} item xs={12} sm={6} md={6}>
            <Grid
              className={"product-details-title"}
              sx={{
                m: 4,
              }}
            >
              <Typography
                sx={{
                  textAlign: "left",
                  fontWeight: "bold",
                  color: "#2B7FA4",
                }}
              >
                Caractéristique
              </Typography>
            </Grid>
          </Grid>
          <Grid className={"product-details"} item xs={12} sm={6} md={6}>
            <Grid
              className={"product-details-title"}
              sx={{
                m: 4,
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  textAlign: "left",
                  fontWeight: "bold",
                  color: "#2B7FA4",
                  borderRadius: 0,
                  border: "none",
                  borderBottom: 2,
                }}
              >
                DESCRIPTION
              </Button>
              <Button
                variant="outlined"
                sx={{
                  textAlign: "left",
                  fontWeight: "bold",
                  color: "#2B7FA4",
                  borderRadius: 0,
                  border: "none",
                  borderBottom: "2 #DADADA",
                }}
              >
                AVIS
              </Button>

              <Typography
                className={"product-details-description"}
                sx={{ mt: 5 }}
              >
                {singleProduct.description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </ThemeProvider>
  );
};
export default ProductDesc;
