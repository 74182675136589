
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA-xC-UpcmTDzmxbiB3t_6wurIhoNO31yw",
  authDomain: "slmelkateb-e7ae8.firebaseapp.com",
  projectId: "slmelkateb-e7ae8",
  storageBucket: "slmelkateb-e7ae8.appspot.com",
  messagingSenderId: "1035453884929",
  appId: "1:1035453884929:web:d36e4c602ded359be52e00"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const provider = new GoogleAuthProvider();

export default app;