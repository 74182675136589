import React from "react";

const Coming = () => {
  const style = {
    color: "#2B7FA4",
    textAlign: "center",
    fontSize: "24px",
    fontWeight: "bold",
    marginTop: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return <div style={style}>Coming Soon ...</div>;
};

export default Coming;
