import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import productsReducer from "./productsSlice";
import categoryReducer from "./categorySlice";
import collectionReducer from "./collectionSlice";
import subcategoriesReducer from "./subCatSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    products: productsReducer,
    category: categoryReducer,
    collection: collectionReducer,
    subcategories: subcategoriesReducer,
  },
});
