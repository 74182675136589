import {createSlice} from '@reduxjs/toolkit'


const initialState ={
    currentUser: null,
    newUser: null,
    loading:false,
    error:false,

}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginStart: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.currentUser = action.payload;
    },
    loginFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    logout: (state) => {
      state.currentUser = null;
      state.loading = false;
      state.error = false;
    },

    registerStart: (state) => {
      state.loading = true;
    },
    registerSuccess: (state, action) => {
      state.loading = false;
      state.newUser = action.payload;
    },
    registerFailure: (state) => {
      state.loading = false;
      state.error = true;
    },


  }
    
})
export const {loginStart,loginSuccess, loginFailure,logout, registerStart, registerSuccess,registerFailure  } = userSlice.actions
export default userSlice.reducer