import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";
import { Container } from "@mui/system";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CategoryIcon from "@mui/icons-material/Category";
import axios from "axios";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getgroupedSubCatStart,
  getgroupedSubCatSuccess,
  getgroupedSubCatFailure,
} from "../redux/subCatSlice";
import {
  getCategoryFailure,
  getCategoryStart,
  getCategorySuccess,
} from "../redux/categorySlice";

const theme = createTheme({
  typography: {
    h6: {
      fontWeight: 300, // or 'bold'
      color: "#2B7FA4",
    },
  },
  a: {
    textDecoration: "none",
    color: "#2B7FA4",
  },
});

const Ul = styled("ul")({
  listStyle: "none",
});

const Li = styled("li")({
  color: "#0C0C0C",
  margin: 5,
  display: "flex",
});

const Categories = (props) => {
  const SetSubCategories =
    useSelector((state) => state.subcategories.groupedSubCat) || [];
  const { categories } = useSelector((state) => state.category);
  const [final, setFinal] = useState();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   const getgroupedSubCat = async () => {
  //     try {
  //       dispatch(getgroupedSubCatStart);
  //       const res = await axios.get(
  //         "http://89.116.228.50:8080/api/subCategory/grouped"
  //       );
  //       console.log("eeelllllllllll", res.data);
  //       dispatch(getgroupedSubCatSuccess(res.data));
  //       console.log("ssssssssssssssssssss", SetSubCategories);
  //     } catch (error) {
  //       dispatch(getgroupedSubCatFailure);
  //       console.log(error);
  //     }
  //   };
  //   getgroupedSubCat();
  // }, []);

  // useEffect(() => {
  //   const getAllcategory = async () => {
  //     dispatch(getCategoryStart);
  //     try {
  //       // category use selector not use state don't forget
  //       const res = await axios.get("http://89.116.228.50:8080/api/category");
  //       dispatch(getCategorySuccess(res.data));
  //       console.log("caat", res.data);
  //     } catch (error) {
  //       dispatch(getCategoryFailure());
  //     }
  //   };
  //   getAllcategory();
  // }, []);

  ///////////////////
  // useEffect(() => {
  //   const fetch = replaceArrayElements(SetSubCategories, categories);
  //   console.log("tttttttttt", fetch);
  //   setFinal(fetch);
  // }, [SetSubCategories, categories]);

  function replaceArrayElements(arr1, arr2) {
    return arr1.map((item) => {
      const newItem = arr2.find((x) => x._id === item._id);
      if (newItem) {
        return { ...newItem, all: item.all };
      }
      return item;
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <Grid item xs={12} sm={6} md={3}>
          <Ul>
            <Li value={"614366ef8944882f54a6eeb7"}>
              <Typography variant="h6">
                <CategoryIcon />
                Sanitaire
              </Typography>
            </Li>
            <Li value={"634d829d46b71ad002a9ee89"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/634d829d46b71ad002a9ee89`}
              >
                Équipement sanitaire
              </Link>
            </Li>
            <Li value={"634d839083008c2afea5534f"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/634d839083008c2afea5534f/`}
              >
                Évier
              </Link>
            </Li>

            <Li value={"634d83a483008c2afea55352"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/634d83a483008c2afea55352`}
              >
                Baignoire
              </Link>
            </Li>
            <Li value={"634d83b483008c2afea55355"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/634d83b483008c2afea55355`}
              >
                Receveur de douche
              </Link>
            </Li>
            <Li value={"634d83cb83008c2afea55358"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/634d83cb83008c2afea55358`}
              >
                Cabine de douche
              </Link>
            </Li>
            <Li value={"637268b36a224972154d65de"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/637268b36a224972154d65de`}
              >
                Bati-support et plaques
              </Link>
            </Li>
            <Li value={"6372691b6a224972154d65e1"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/6372691b6a224972154d65e1`}
                onClick={props.onClosePopover}
              >
                Meuble DB, colonne et miroir
              </Link>
            </Li>
            <Li value={"637269346a224972154d65e4"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/637269346a224972154d65e4`}
              >
                Accesoir SDB
              </Link>
            </Li>

            <Li>
              <Typography variant="h6">
                <CategoryIcon />
                Revêtement
              </Typography>
            </Li>
            <Li value={"634d841383008c2afea5535b"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/634d841383008c2afea5535b`}
              >
                Faience
              </Link>
            </Li>
            <Li value={"634d842f83008c2afea5535e"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/634d842f83008c2afea5535e`}
              >
                Grès
              </Link>
            </Li>
            <Li value={"637397c0c9c8a3db657f9fb3"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/637397c0c9c8a3db657f9fb3`}
              >
                Glass mosaique
              </Link>
            </Li>
            <Li value={"637397d4c9c8a3db657f9fb6"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/637397d4c9c8a3db657f9fb6`}
              >
                Terre cuite
              </Link>
            </Li>

            <Li value={"634d844183008c2afea55361"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/634d844183008c2afea55361`}
              >
                Ciment colle, Carreau joint et Fixation
              </Link>
            </Li>
          </Ul>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Ul>
            <Li>
              <Typography variant="h6">
                <CategoryIcon />
                Robinetterie
              </Typography>
            </Li>
            <Li value={"634d849083008c2afea55364"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/634d849083008c2afea55364`}
              >
                Mitigeur
              </Link>
            </Li>
            <Li value={"634d849f83008c2afea55367"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/634d849f83008c2afea55367`}
              >
                Mélangeur
              </Link>
            </Li>
            <Li value={"634d84b883008c2afea5536a"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/634d84b883008c2afea5536a`}
              >
                Robinet
              </Link>
            </Li>
            <Li value={"634d84cb83008c2afea5536d"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/634d84cb83008c2afea5536d`}
              >
                Collectivité temporisé
              </Link>
            </Li>
            <Li value={"634d84dd83008c2afea55370"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/634d84dd83008c2afea55370`}
              >
                Filtration domestique
              </Link>
            </Li>

            <Li>
              <Typography variant="h6">
                <CategoryIcon />
                Chauffage et Climatisation
              </Typography>
            </Li>

            <Li value={"634d853983008c2afea55376"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/634d853983008c2afea55376`}
              >
                Chauffe-bain
              </Link>
            </Li>

            <Li value={"634d852a83008c2afea55373"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/634d852a83008c2afea55373`}
              >
                Chaudière
              </Link>
            </Li>
            <Li value={"634d854983008c2afea55379"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/634d854983008c2afea55379`}
              >
                Elément radiateur
              </Link>
            </Li>
            <Li value={"6373bb06c9c8a3db657f9fc5"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/6373bb06c9c8a3db657f9fc5`}
              >
                Sèche Serviette
              </Link>
            </Li>
            <Li value={"6373bb1ec9c8a3db657f9fc8"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/6373bb1ec9c8a3db657f9fc8`}
              >
                Chauffage par le sol
              </Link>
            </Li>
            <Li value={"6373bb32c9c8a3db657f9fcb"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/6373bb32c9c8a3db657f9fcb`}
              >
                Photovoltaïque
              </Link>
            </Li>
            <Li value={"6373bb48c9c8a3db657f9fce"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/6373bb48c9c8a3db657f9fce`}
              >
                climatiseurs
              </Link>
            </Li>
          </Ul>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Ul>
            <Li>
              <Typography variant="h6">
                <CategoryIcon />
                Porte, Cuisine et Dressing
              </Typography>
            </Li>
            <Li value={"6373c100c9c8a3db657f9fda"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/6373c100c9c8a3db657f9fda`}
              >
                Porte
              </Link>
            </Li>

            <Li value={"643e9b78ee34a1d52552f436"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/643e9b78ee34a1d52552f436`}
              >
                Contre chassis
              </Link>
            </Li>

            <Li value={"634d870e83008c2afea55397"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/634d870e83008c2afea55397`}
              >
                Electroménager
              </Link>
            </Li>

            <Li value={"634d86f183008c2afea55391"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/634d86f183008c2afea55391`}
              >
                Meuble cuisine et Dressing
              </Link>
            </Li>
          </Ul>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Ul>
            <Li>
              <Typography variant="h6">
                <CategoryIcon />
                Quincaillerie générale
              </Typography>
            </Li>
            <Li value={"634d85c283008c2afea55382"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/634d85c283008c2afea55382`}
              >
                Electricité
              </Link>
            </Li>
            <Li value={"634d85a283008c2afea5537c"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/634d85a283008c2afea5537c`}
              >
                Plomberie et Accessoires
              </Link>
            </Li>

            <Li value={"6374d2437c971f87dd84655f"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/6374d2437c971f87dd84655f`}
              >
                Peinture et Accessoires
              </Link>
            </Li>

            <Li value={"6374d2647c971f87dd846565"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/6374d2647c971f87dd846565`}
              >
                Etanchéité
              </Link>
            </Li>

            <Li value={"6374d28b7c971f87dd84656b"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/6374d28b7c971f87dd84656b`}
              >
                Menuiserie
              </Link>
            </Li>

            <Li value={"6374d2e37c971f87dd846574"} onClick={props.onClose}>
              <Link
                style={{ textDecoration: "none", color: "#0C0C0C" }}
                to={`/products/6374d2e37c971f87dd846574`}
              >
                Bricolage
              </Link>
            </Li>
          </Ul>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
export default Categories;
