import * as React from "react";
import { Container } from "@mui/system";
import { Grid, Typography, Button, Modal } from "@mui/material";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import PinDropIcon from "@mui/icons-material/PinDrop";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import ezzahra from "../../images/Ezzahra.jpg";
import Img2 from "../../images/ins/insp4.jpg";
import kairouan from "../../images/kairouan.jpg";
import ariana from "../../images/ariana.jpg";
import bardo from "../../images/bardo.jpg";
import mghira from "../../images/mghira.jpg";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

const Img = styled("img")({
  ml: "auto",
  mr: "auto",
  width: "80%",
});

const shops = [
  {
    name: "Magasin Ezzahra",
    img: ezzahra,
    tel: "+(216) 31336060 / 54 555 000 ",
    Adresse: "Av. 2 Mars 1934, Cite El Habib Ezzahra ben Arous",
    localisation:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3197.4593180092747!2d10.315716399999998!3d36.7355446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd4911f09f1011%3A0xa1383980f8e465d1!2sSLM%20EL%20KATEB%20(Agence%20Ezzahra%20)!5e0!3m2!1sfr!2stn!4v1671723781678!5m2!1sfr!2stn",
  },
  {
    name: "Magasin Ariana",
    img: ariana,
    tel: "+(216) 31 336 082 / 54 555 000 ",
    Adresse: "44 Rue Abou Kacem Echabbi-Ariana 2080",
    localisation:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3191.884385324346!2d10.186116!3d36.86918609999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12e2cb4f448806f9%3A0x2aea9008d0a7231c!2sSLM%20El%20Kateb%20(%20Agence%20Ariana%20)!5e0!3m2!1sfr!2stn!4v1671723721030!5m2!1sfr!2stn",
  },
  {
    name: "Magasin Bardo",
    img: bardo,
    tel: "+(216) 31 336 083 / 54 555 000 ",
    Adresse: "Avenue 14 janvier -Route X 6 le Bardo 2042 ",
    localisation:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3194.013429773899!2d10.128724699999998!3d36.8181981!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd33aba5ca3037%3A0xee3b457ca03cbc84!2sSLM%20El%20Kateb%20(%20Agence%20Bardo%20)!5e0!3m2!1sfr!2stn!4v1671722683563!5m2!1sfr!2stn",
  },
  {
    name: "Magasin Kairouan",
    img: kairouan,
    tel: "+(216) 54 555 000 ",
    Adresse: "Route de weslatiya, près du stade Hamda laawani ",
    localisation:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.772520427333!2d10.07683961436211!3d35.68260283750597!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fdcfc6edf1b951%3A0xbb3ab495245a86e2!2sSLM%20EL%20KATEB%20(Agence%20Kairouan%20)!5e0!3m2!1sfr!2stn!4v1671722622136!5m2!1sfr!2stn",
  },

  {
    name: "Magasin Mghira",
    img: mghira,
    tel: "+(216) 54 555 000",
    Adresse: "Autoroute 3 Km 9 fouchana 2082  ",
    localisation:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6397.2359251246535!2d10.173091746527113!3d36.70771730481122!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xae23d97c77e90460!2sSLM%20EL%20KATEB%20(Agence%20Fouchana%20)!5e0!3m2!1sfr!2stn!4v1672407946397!5m2!1sfr!2stn",
  },
];

const style = {
  position: "absolute",
  bgcolor: "background.paper",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  boxShadow: 24,
  p: 2,
};
const theme = createTheme({
  typography: {
    width: "610px",
    height: "190px",
    left: "730px",
    top: "357px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "150%",
  },
});

const Shops = () => {
  const [open, setOpen] = React.useState(false);
  const [location, setLocation] = React.useState("");

  const handleClickOpen = (shop) => {
    setOpen(true);
    setLocation(shop.localisation);
  };

  const handleClose = () => {
    setOpen(false);
    setLocation("");
  };

  return (
    <ThemeProvider theme={theme}>
      <Navbar />

      <Container maxWidth="xl">
        <Grid>
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{
              mb: 1,
            }}
          >
            <Link underline="hover" color="inherit" href="/shops">
              Nos Magasins
            </Link>
          </Breadcrumbs>
          <Typography
            variant="h6"
            component="div"
            sx={{
              m: 2,
              ml: 0,
              fontWeight: "bold",
              color: "#2B7FA4",
            }}
          >
            Nos magasins
          </Typography>
        </Grid>
      </Container>
      <Container
        maxWidth="xxl"
        sx={{
          bgcolor: "#FCF8F3",
        }}
      >
        <Container maxWidth="xl">
          <Grid container>
            <Grid item xs={12} sm={6} md={6} sx={{ textAlign: "justify" }}>
              <Typography m={4} variant="body1" gutterBottom>
                Qui somme nous ?<br />
                Vous êtes un particulier, un professionnel ou un artisan à la
                recherche de produits de qualité pour concrétiser vos projets ?
                Vous pouvez compter sur notre large sélection des articles
                répartis sur plusieurs gammes pour trouver votre besoin
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Img alt="" src={Img2} sx={{ m: 4 }} />
            </Grid>
          </Grid>
        </Container>
      </Container>
      {/* les magasin */}
      <Container maxWidth="xl">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          closeAfterTransition
        >
          <Grid sx={style}>
            <iframe
              width="100%"
              height="600"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              src={location}
            ></iframe>
          </Grid>
        </Modal>
        <Grid container sx={{ mb: 5 }}>
          {shops.map((shop, key) => (
            <Grid item xs={12} sm={4} md={4} width="2em" maxHeight="600px">
              <Card sx={{ m: 6 }}>
                <Grid sx={{ m: 2 }}>
                  <CardMedia
                    component="img"
                    image={shop.img}
                    alt="Store Image"
                    sx={{ borderRadius: 2, height: "250px", objectFit: "fill" }}
                  />
                </Grid>
                <CardContent>
                  <Typography
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      color: "#2B7FA4",
                      fontFamily: "Poppins",
                      fontSyle: "normal",
                      fontWeight: " 600",
                      fontSize: "18px",
                    }}
                    component="h2"
                  >
                    <PinDropIcon />
                    {shop.name}
                    <Typography
                      sx={{
                        float: "right",
                        color: "#737B7D",
                        fontFamily: "Poppins",
                        fontSize: "10px",
                      }}
                    >
                      Magasin N°0
                      {key + 1}
                    </Typography>
                  </Typography>

                  <Typography
                    gutterBottom
                    sx={{
                      fontFamily: "Poppins",
                      m: 1,
                      textAlign: "left",
                      color: "#898989",
                      fontFamily: "Inter",
                      color: "#737B7D",
                      fontSize: "16px",
                    }}
                  >
                    Adress: <br />
                    {shop.Adresse}
                  </Typography>

                  <Typography
                    gutterBottom
                    sx={{
                      fontFamily: "Poppins",
                      m: 1,
                      textAlign: "left",
                      color: "#898989",
                      fontFamily: "Inter",
                      fontSize: "16px",
                    }}
                  >
                    Appeler: <br />
                    {shop.tel}
                  </Typography>

                  <Typography
                    sx={{
                      m: 1,
                      textAlign: "left",
                      color: "#898989",
                      fontFamily: "Inter",
                    }}
                    gutterBottom
                  >
                    Livraison sur toute la Tunisie
                  </Typography>

                  <Button
                    onClick={() => handleClickOpen(shop)}
                    color="primary"
                    variant="contained"
                    sx={{
                      mb: 2,
                      fontFamily: "Gilroy",
                      background: "#2B7FA4",
                      textTransform: "none",
                      color: "white",
                      margin: "0% 20% 0% 20%",
                    }}
                  >
                    Voir dans la carte
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Footer />
    </ThemeProvider>
  );
};
export default Shops;
