import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import FormGroup from "@mui/material/FormGroup";
import InputBase from "@mui/material/InputBase";
import TextField from "@mui/material/TextField";
import { Box, Grid } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  addCollectionStart,
  addCollectionSuccess,
  addCollectionFailure,
} from "../redux/collectionSlice";

import Img1 from "../images/img1.jpg";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#2B7FA4",
    },
  },
  typography: {
    color: "#737B7D",
    textDecoration: "none",
    button: {
      textTransform: "none",
      fontSize: "1rem",
      backgroundColor: " #2B7FA4",
    },
  },
});
const styles = {
  paperContainer: {
    height: "200px",
    backgroundImage: `url(${Img1})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    width: "400px",
    textAlign: "center",
  },
  imageUpload: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: " -25px 0 0 -25px",
  },
};

export default function AddCollection() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [brand, setBrand] = useState("");
  const [colProduct, setColProduct] = useState();
  const [category, setCategory] = useState();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch();

  const handlAddProduct = async (ref) => {
    console.log("before", products);
    console.log("koooool", colProduct);

    try {
      const res = await axios.get(`/api/revetproduct/revcol?ref=${colProduct}`);
      const prod = res.data;
      console.log("prrroood", prod);
      setProducts((products) => [...products, prod]);
      console.log("after", products);
    } catch (error) {
      console.log("no product with this refernce");
    }
  };

  const uploadFileHandler = async (e) => {
    const files = e.target.files;
    const formData = new FormData();
    for (const file of files) {
      formData.append("files", file);
    }

    setLoading(true);
    try {
      const config = {
        Headers: {
          "Content-Type": "multipart/form/data",
        },
      };

      const { data } = await axios.post("/api/upload", formData, config);
      console.log("imaaaages", data);
      setImages(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(addCollectionStart());
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        "/api/collections",
        {
          name,
          products,
          images,
          description,
          brand,
          category,
        },
        config
      );
      dispatch(addCollectionSuccess(data));
    } catch (error) {
      dispatch(addCollectionFailure());
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Typography variant="h4" sx={{ color: "#737B7D" }} m={2}>
        Ajouter une Collection
      </Typography>
      <Grid m={2}>
        <TextField
          required
          id="outlined-multiline-flexible"
          label="Nom collection"
          placeholder="Nom collection"
          // value=""
          color="secondary"
          maxRows={5}
          onChange={(e) => setName(e.target.value)}
        />
      </Grid>
      <FormControl fullWidth>
        <InputLabel> Marque</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="revêtement ?"
          onChange={(e) => setBrand(e.target.value)}
        >
          <MenuItem value={"Azteca"}> Azteca</MenuItem>
          <MenuItem value={"Inalco"}> Inalco</MenuItem>
          <MenuItem value={"Casalgrande Padana"}> Casalgrande Padana</MenuItem>
          <MenuItem value={"Azulev"}> Azulev</MenuItem>
          <MenuItem value={"Azuvi"}> Azuvi</MenuItem>
          <MenuItem value={"STN"}> STN</MenuItem>
          <MenuItem value={"Alcor"}> Alcor</MenuItem>
          <MenuItem value={"Vives"}> Vives</MenuItem>
          <MenuItem value={"Ktl"}> KTL Ceramica</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel> Category </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="revêtement ?"
          onChange={(e) => setCategory(e.target.value)}
        >
          <MenuItem value={"634d842f83008c2afea5535e"}> Grès</MenuItem>
          <MenuItem value={"634d841383008c2afea5535b"}> Faience</MenuItem>
        </Select>
      </FormControl>

      <Grid m={2}>
        <TextField
          required
          id="outlined-multiline-flexible"
          label="Description"
          placeholder="Description"
          // value=""
          color="secondary"
          maxRows={5}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Grid>
      <Grid>
        <Box
          component="form"
          sx={{
            ml: 2,
          }}
          spacing={2}
          noValidate
          autoComplete="on"
        >
          <Typography variant="h6" sx={{ color: "#737B7D" }}>
            Ajouter photos de la collections
          </Typography>

          <Grid m={2} style={styles.paperContainer}>
            <Button
              variant="contained"
              component="label"
              color="secondary"
              style={styles.imageUpload}
            >
              Ajouter
              {/* <input hidden accept="image/*" multiple type="file" /> */}
              <input
                //  hidden
                //  accept="image/*"
                multiple
                type="file"
                // onChange={(e)=> setImages(e.target.files)}
                onChange={uploadFileHandler}
              />
            </Button>
          </Grid>

          <Grid m={2}>
            <Typography variant="h6" sx={{ color: "#737B7D" }}>
              Articles de la collection
            </Typography>
            <TextField
              required
              id="outlined-multiline-flexible"
              label="Code Article"
              placeholder="Code Article"
              multiline
              // value=""
              color="secondary"
              maxRows={5}
              onChange={(e) => {
                setColProduct(e.target.value);
              }}
            />
            <Button
              sx={{ m: 1 }}
              variant="contained"
              component="button"
              color="secondary"
              onClick={handlAddProduct}
            >
              Recherche
            </Button>
          </Grid>
          <Grid m={2}>
            {products.map((product) => (
              <div> {product.name}</div>
            ))}
            <Grid></Grid>
          </Grid>
        </Box>
        <Button
          m={2}
          variant="contained"
          component="button"
          color="secondary"
          onClick={handleSubmit}
        >
          Validation de la collection
        </Button>
      </Grid>
    </ThemeProvider>
  );
}
