import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Signup from "../src/components/Auth/Signup";
import Login from "../src/components/Auth/Login";
import Home from "../src/components/Home/Home";
import About from "../src/components/About/About";
import AllProducts from "../src/components/Products/AllProducts";
import Dashboard from "./Dashboard/index";
import AddProduct from "./Dashboard/AjouterProduit";
import EditProduct from "./Dashboard/ModifierProduit";
import AddCollection from "./Dashboard/AjouterCollection";
import AddRevetProduct from "./Dashboard/AjouterRevetproduct";
import Demandes from "./Dashboard/Demandes";
import Categories from "./components/Categories";
import Promotion from "./components/Tendance/Promotion";
import Shops from "./components/Shops/Shops";
import Search from "./components/Search/Search";
import ProductDesc from "./components/Products/ProductDes";
import ProductRev from "./components/Products/revetment";
import Contact from "./components/Contact/Contact";
import Revetement from "./components/Products/revetment";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="product">
        <Route path=":id" element={<ProductDesc />} />
        <Route path="revetement/:id" element={<Revetement />} />
      </Route>
      <Route path="/about" element={<About />} />
      <Route path="/shops" element={<Shops />} />
      <Route path="/products" element={<AllProducts />} />
      <Route path="/products/:path" element={<AllProducts />} />
      <Route path="/categories" element={<Categories />} />
      <Route path="/promotions" element={<Promotion />} />
      <Route path="/search" element={<Search />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/Dashboard" element={<Dashboard />}>
        <Route path="addProduct" element={<AddProduct />} />
        <Route path="demandes" element={<Demandes />} />
        <Route path="EditProduct" element={<EditProduct />} />
        <Route path="AddCollection" element={<AddCollection />} />
        <Route path="AddRevetProduct" element={<AddRevetProduct />} />
      </Route>
    </Routes>
  );
}

export default App;
