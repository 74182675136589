import {createSlice} from '@reduxjs/toolkit'


const initialState ={
    productList:[],
    loading:false,
    error:false,

}

export const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    productListStart: (state) => {
      state.loading = true;
    },
    productListSuccess: (state, action) => {
      state.loading = false;
      state.productList = action.payload;
    },
    productListFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    
   
  }
    
})
export const {productListStart,productListSuccess, productListFailure } = productSlice.actions
export default productSlice.reducer