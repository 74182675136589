import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productList: [],
  addedProduct: {},
  singleProduct: {},
  subCategoryList: [],
  Subproducts: [],
  pages: 0,
  page: 0,
  loading: false,
  error: false,
};

export const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    productListStart: (state) => {
      state.loading = true;
    },
    productListSuccess: (state, action) => {
      state.loading = false;
      state.productList = action.payload;
    },
    productListFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    addProductStart: (state) => {
      state.loading = true;
    },
    addProductSuccess: (state, action) => {
      state.loading = false;
      state.addedProduct = action.payload;
    },
    addProductFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    fetchStart: (state) => {
      state.loading = true;
    },
    fetchSuccess: (state, action) => {
      state.loading = false;
      state.singleProduct = action.payload;
    },
    fetchFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    fetchSubCategoryStart: (state) => {
      state.loading = true;
    },
    fetchSubCategorySucces: (state, action) => {
      state.loading = false;
      state.subCategoryList = action.payload;
    },
    fetchSubCategoryFailure: (state) => {
      state.loading = false;
      state.error = true;
    },

    fetchProductBySubCategoryStart: (state) => {
      state.loading = true;
    },
    fetchProductBySubCategorySuccess: (state, action) => {
      state.loading = false;
      state.Subproducts = action.payload.products;
      state.pages = action.payload.pages;
      state.page = action.payload.page;
    },
    fetchProductBySubCategoryFailure: (state, action) => {
      state.loading = false;
      state.error = true;
    },
  },
});
export const {
  productListStart,
  productListSuccess,
  productListFailure,
  addProductStart,
  addProductSuccess,
  addProductFailure,
  fetchStart,
  fetchSuccess,
  fetchFailure,
  fetchSubCategoryStart,
  fetchSubCategorySucces,
  fetchSubCategoryFailure,
  fetchProductBySubCategoryStart,
  fetchProductBySubCategorySuccess,
  fetchProductBySubCategoryFailure,
} = productSlice.actions;
export default productSlice.reducer;
