import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: false,
  addedRevetProduct: {},
};

export const revetProductSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    addRevetProductStart: (state) => {
      state.loading = true;
    },
    addRevetProductSuccess: (state, action) => {
      state.loading = false;
      state.addedRevetProduct = action.payload;
    },
    addRevetProductFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export const {
  addRevetProductStart,
  addRevetProductSuccess,
  addRevetProductFailure,
} = revetProductSlice.actions;
export default revetProductSlice.reducer;
