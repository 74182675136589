import React, { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  registerFailure,
  registerStart,
  registerSuccess,
} from "../../redux/userSlice";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import { Link } from "react-router-dom";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import logo from "../../images/logo.png";

const theme = createTheme();

export default function SignUp() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    if (password !== confirmPassword) {
      setMessage("vérifiez votre mot de pass");
    } else {
      dispatch(registerStart(name, email, password));
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        const { data } = await axios.post(
          "/api/users",
          { name, email, password },
          config
        );
        dispatch(registerSuccess(data));
        localStorage.setItem("userinfo", JSON.stringify(data));
      } catch {
        dispatch(registerFailure());
      }
    }

    return (
      <ThemeProvider theme={theme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          <Grid
            item
            xs={4}
            sm={4}
            md={7}
            sx={{
              backgroundColor: "#2B7FA4",
            }}
          ></Grid>
          <Grid
            item
            xs={8}
            sm={8}
            md={5}
            elevation={6}
            square
            sx={{ height: "100vh" }}
          >
            <Box
              sx={{
                my: 6,
                mx: 10,
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <img
                 src={logo}
                 alt="Home"
                 style={{ maxWidth: 100, marginRight: "10px" }}
               />
                <FormControl variant="standard" sx={{ mt: 0, width: 120 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    English (UK)
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    // value={english}
                    // onChange={handleChange}
                    label="language"
                  >
                    <MenuItem value="">
                      <em>English (UK)</em>
                    </MenuItem>
                    <MenuItem value="">Francais (FR)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Box sx={{ mt: 2, mb: 2, display: "block", textAlign: "left" }}>
                <Typography>Bienvenue</Typography>
                <Typography component="h1" variant="h5">
                  Inscrivez-vous gratuitement
                </Typography>
              </Box>

              <Box component="form" noValidate onSubmit={handleSubmit}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="nom"
                  label="Nom et prenom"
                  name="nom"
                  autoComplete="username"
                  autoFocus
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Mot de passe"
                  type="password"
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPassword"
                  label="confirmez votre mot de passe "
                  type="password"
                  id="confirmPassword"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />

                <Box
                  sx={{
                    mt: 2,
                    mb: 1,
                    mx: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                ></Box>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{ mb: 2, backgroundColor: "#2B7FA4" }}
                >
                  Enregistrer
                </Button>

                <Typography
                  sx={{ mb: 1, textAlign: "center", color: "#A3A3A3" }}
                >
                  {" "}
                  - OR -{" "}
                </Typography>
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <Button
                    sx={{ m: 1, backgroundColor: "#3781FF" }}
                    type="submit"
                    variant="contained"
                  >
                    <GoogleIcon />
                    Login with Google
                  </Button>
                  <Button
                    sx={{ m: 1, backgroundColor: "#3B5998" }}
                    type="submit"
                    variant="contained"
                  >
                    <FacebookIcon />
                    Login with Facebook
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  j'ai deja un compte.
                  <Link to="/login">Connectez-vous</Link>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  };
}
