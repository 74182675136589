import * as React from "react";
import { Grid, Stack } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "react-alice-carousel/lib/alice-carousel.css";
import Typography from "@mui/material/Typography";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button } from "@mui/material";
import { Container } from "@mui/system";

//function that will import all partner images
function importAll(r) {
  let images = [];
  r.keys().map((item, index) => {
    images.push(r(item));
  });
  return images;
}

const images = importAll(
  require.context("../../images/ins", false, /\.(png|jpe?g|svg)$/)
);
const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
  }
});

export default function TendanceSlide() {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xxl" sx={{ backgroundColor: "#FCF8F3" }}>
        <Grid container>
          <Grid item xs={12} sm={4} md={4}>
            <Typography
              variant="h4"
              component="div"
              sx={{
                my: 4,
                mx: 10,
                fontWeight: "bold",
              }}
              textAlign="left"
            >
              Les Nouvelles Tendance
            </Typography>
            <Typography
              sx={{
                my: 4,
                mx: 10,
              }}
            >
              Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore
            </Typography>
            <Stack
              sx={{ my: 4, mx: 10 }}
              direction="row"
              spacing={2}
              justifyContent="left"
            >
              <Button
              color="primary"
                variant="contained"
                sx={{
                  fontFamily: "Gilroy",
                  background: "#2B7FA4",
                  alignItems: "center",
                  textTransform: "none",
                  color:"white"
                }}
                href="/tendances"
              >
                Explore Plus
              </Button>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={8} md={8} >
          <Carousel  
            additionalTransfrom={0}
            arrows
            autoPlaySpeed={3000}
            centerMode={false}
            className=""
            containerClass="container-with-dots"
            draggable
            focusOnSelect={false}
            infinite
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024
                },
                items: 3,
                partialVisibilityGutter: 40
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0
                },
                items: 1,
                partialVisibilityGutter: 30
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464
                },
                items: 2,
                partialVisibilityGutter: 30
              }
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
            sx={{margin:"50px 50px"}}
           >
          {images.map((image, key) => (
            <img src={image} height="100%" width="95%" />
          ))}
        </Carousel>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
